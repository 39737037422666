
    import './styles.scoped.css';
    export default {
  "button-trigger": "awsui_button-trigger_18eso_12p9a_157",
  "arrow": "awsui_arrow_18eso_12p9a_157",
  "in-filtering-token": "awsui_in-filtering-token_18eso_12p9a_220",
  "has-caret": "awsui_has-caret_18eso_12p9a_250",
  "placeholder": "awsui_placeholder_18eso_12p9a_253",
  "pressed": "awsui_pressed_18eso_12p9a_266",
  "disabled": "awsui_disabled_18eso_12p9a_269",
  "readonly": "awsui_readonly_18eso_12p9a_285",
  "invalid": "awsui_invalid_18eso_12p9a_307",
  "warning": "awsui_warning_18eso_12p9a_316",
  "inline-tokens": "awsui_inline-tokens_18eso_12p9a_325"
};
  