
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_1wfvt_185",
  "fit-height": "awsui_fit-height_14iqq_1wfvt_221",
  "with-side-media": "awsui_with-side-media_14iqq_1wfvt_226",
  "variant-default": "awsui_variant-default_14iqq_1wfvt_229",
  "variant-stacked": "awsui_variant-stacked_14iqq_1wfvt_229",
  "refresh": "awsui_refresh_14iqq_1wfvt_237",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_1wfvt_287",
  "with-top-media": "awsui_with-top-media_14iqq_1wfvt_299",
  "content-wrapper": "awsui_content-wrapper_14iqq_1wfvt_304",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_1wfvt_309",
  "media": "awsui_media_14iqq_1wfvt_316",
  "media-top": "awsui_media-top_14iqq_1wfvt_334",
  "media-side": "awsui_media-side_14iqq_1wfvt_339",
  "header": "awsui_header_14iqq_1wfvt_345",
  "header-full-page": "awsui_header-full-page_14iqq_1wfvt_350",
  "header-with-media": "awsui_header-with-media_14iqq_1wfvt_353",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_1wfvt_359",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_1wfvt_363",
  "header-stuck": "awsui_header-stuck_14iqq_1wfvt_369",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_1wfvt_379",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_1wfvt_382",
  "with-paddings": "awsui_with-paddings_14iqq_1wfvt_388",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_1wfvt_397",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_1wfvt_455",
  "header-cover": "awsui_header-cover_14iqq_1wfvt_458",
  "content": "awsui_content_14iqq_1wfvt_304",
  "content-fit-height": "awsui_content-fit-height_14iqq_1wfvt_486",
  "content-with-media": "awsui_content-with-media_14iqq_1wfvt_496",
  "footer": "awsui_footer_14iqq_1wfvt_500",
  "with-divider": "awsui_with-divider_14iqq_1wfvt_504"
};
  