import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function HouseholdMembers ({ item, formValues, setFormValues, suffixOptions, raceEthnicityOptions, roleOptions, tribeOptions, translate }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          {translate('Household Member Information')}
        </RadHeader>
    }
    >
      <RadSpaceBetween size='s'>
        <RadBox color='text-body-secondary'>
          <RadSpaceBetween size='xxs'>
            <span>{translate('Use the following section to add members to your household. In this section, only include household members who are not attending school (Head Start or preK-12).A household is defined as a group of related or unrelated individuals who live together and share income and expenses. This might include parents, grandparents, aunts, uncles, adult children, or children under the age of three who are not attending school.')}</span>
            <span>{translate('You can add as many household members as needed by selecting the "Add Household Member" button.')}</span>
            <span>{translate('For this application, an eligible child must be attending school or have graduated this year. Children who turned three prior to September 1, 2023 and attend Head Start are eligible to apply for benefits.')}</span>
          </RadSpaceBetween>
        </RadBox>

        {formValues.householdMembers.map((item) =>
          <RadSpaceBetween size='xxs' key={`household-member-${item.id ?? item.uuid}`}>
            <Editor
              item={item}
              formValues={formValues}
              setFormValues={setFormValues}
              suffixOptions={suffixOptions}
              raceEthnicityOptions={raceEthnicityOptions}
              roleOptions={roleOptions}
              tribeOptions={tribeOptions}
              translate={translate}
            />
          </RadSpaceBetween>
        )}
        <br />
        <RadButton
          onClick={() => {
            const householdMembers = formValues.householdMembers
            householdMembers.push({ uuid: uuidv4(), snap: false, tanf: false, fdpir: false, medicaid: false, none: false, otherTribe: false, primaryContact: false })
            setFormValues({ ...formValues, householdMembers })
          }}
        >
          {translate('Add household member')}
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}

export function Editor ({ item, formValues, setFormValues, suffixOptions, raceEthnicityOptions, roleOptions, tribeOptions, translate }) {
  return (
    <RadSpaceBetween size='l'>
      {item !== formValues.householdMembers[0] && <RadDivider />}
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <RadFormField label={translate('First Name')} field={`householdMember.${item.id ?? item.uuid}.firstName`} required>
          <RadInput
            disabled={item.primaryContact}
            placeholder={translate('Enter first name')}
            ariaRequired
            value={item.firstName}
            onChange={({ detail }) => {
              const householdMembers = formValues.householdMembers
              item.firstName = detail.value
              setFormValues({ ...formValues, householdMembers })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Middle Name')} field={`householdMember.${item.id ?? item.uuid}.middleName`}>
          <RadInput
            disabled={item.primaryContact}
            placeholder={translate('Enter middle name')}
            ariaRequired
            value={item.middleName}
            onChange={({ detail }) => {
              const householdMembers = formValues.householdMembers
              item.middleName = detail.value
              setFormValues({ ...formValues, householdMembers })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Last Name')} field={`householdMember.${item.id ?? item.uuid}.lastName`} required>
          <RadInput
            disabled={item.primaryContact}
            placeholder={translate('Enter last name')}
            ariaRequired
            value={item.lastName}
            onChange={({ detail }) => {
              const householdMembers = formValues.householdMembers
              item.lastName = detail.value
              setFormValues({ ...formValues, householdMembers })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Suffix')} field={`householdMember.${item.id ?? item.uuid}.suffix`}>
          <RadSelect
            disabled={item.primaryContact}
            filteringType='auto'
            selectedOption={suffixOptions.find(x => x.value === item.suffix)}
            onChange={({ detail }) => {
              const householdMembers = formValues.householdMembers
              item.suffix = detail.selectedOption.value
              setFormValues({ ...formValues, householdMembers })
            }}
            options={suffixOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a suffix')}
            empty={translate('No matches found')}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <RadFormField label={translate('Race / Ethnicity')} field={`householdMember.${item.id ?? item.uuid}.raceEthnicity`}>
          <RadSelect
            filteringType='auto'
            selectedOption={raceEthnicityOptions.find(x => x.value === item.raceEthnicity)}
            onChange={({ detail }) => {
              const householdMembers = formValues.householdMembers
              item.raceEthnicity = detail.selectedOption.value
              setFormValues({ ...formValues, householdMembers })
            }}
            options={raceEthnicityOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a race / ethnicity')}
            empty={translate('No matches found')}
          />
        </RadFormField>
        {item.raceEthnicity === 'Native American' &&
          <RadFormField label={translate('Tribe')} field={`householdMember.${item.id ?? item.uuid}.raceEthnicity`}>
            <RadSelect
              filteringType='auto'
              selectedOption={item.otherTribe ? { value: 'Other', label: translate('Other') } : tribeOptions.find(x => x.value === item.tribe)}
              onChange={({ detail }) => {
                const householdMembers = formValues.householdMembers
                if (detail.selectedOption.value === 'Other') {
                  item.otherTribe = true
                  item.tribe = null
                } else {
                  item.otherTribe = false
                  item.tribe = detail.selectedOption.value
                }
                setFormValues({ ...formValues, householdMembers })
              }}
              options={tribeOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel={translate('Selected')}
              placeholder={translate('Choose a tribe')}
              empty={translate('No matches found')}
            />
          </RadFormField>}
        {item.otherTribe &&
          <RadFormField label={translate('If other tribe please specify')} field={`householdMember.${item.id ?? item.uuid}.raceEthnicity`}>
            <RadInput
              placeholder={translate('Enter tribe')}
              value={item.tribe}
              onChange={({ detail }) => {
                const householdMembers = formValues.householdMembers
                item.tribe = detail.value
                setFormValues({ ...formValues, householdMembers })
              }}
            />
          </RadFormField>}
        <RadFormField label={translate('Birth Date')} field={`householdMember.${item.id ?? item.uuid}.birthDate`} required>
          <RadInput
            ariaRequired
            disabled={item.primaryContact}
            type='date'
            placeholder={translate('Enter birth date')}
            value={item.birthDate}
            onChange={({ detail }) => {
              const householdMembers = formValues.householdMembers
              item.birthDate = detail.value
              setFormValues({ ...formValues, householdMembers })
            }}
          />
        </RadFormField>
      </RadGrid>
      <RadFormField label={translate('Role')} field={`householdMember.${item.id ?? item.uuid}.role`} required>
        <RadSelect
          filteringType='auto'
          selectedOption={roleOptions.find(x => x.value === item.role)}
          onChange={({ detail }) => {
            const householdMembers = formValues.householdMembers
            item.role = detail.selectedOption.value
            setFormValues({ ...formValues, householdMembers })
          }}
          options={roleOptions}
          enteredTextLabel={value => value}
          selectedAriaLabel={translate('Selected')}
          placeholder={translate('Choose a role')}
          empty={translate('No matches found')}
        />
      </RadFormField>
      <RadBox>
        <RadButton
          wrapText={false}
          onClick={() => {
            const householdMembers = formValues.householdMembers.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
            setFormValues({ ...formValues, householdMembers })
          }}
        >
          {translate('Remove household member')}
        </RadButton>
      </RadBox>
    </RadSpaceBetween>
  )
}
