import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatCurrency, formatDate, formatDateTime } from '../common/utilities'
import { states } from '../audit/AuditListRowForm'

export function ApplicationDetail () {
  const navigate = useNavigate()
  const { applicationId } = useParams()
  const [reloadCounter, setReloadCounter] = useState(0)
  const { data: application } = useGet(`/api/application/${applicationId}?reload=${reloadCounter}`, true)
  const { data: userInfo } = useGet('/api/user/current')
  const confirmResend = useConfirm('Resend confirmation email?', 'Please confirm you would like to resend the confirmation email to the application primary contact?', 'Resend Confirmation Email', () => { resend() })
  const resend = usePost(`/api/application/send-confirmation/${applicationId}`, {})
  const confirmReject = useConfirm('Reject application?', 'Please confirm you would like to reject the application?', 'Reject', () => { reject() })
  const reject = usePost(`/api/application/reject/${applicationId}`, {}, () => { setReloadCounter(reloadCounter + 1) })
  const confirmDelete = useConfirm('Delete application?', 'Please confirm you would like to delete the application?', 'Delete', () => { remove() })
  const remove = useDelete(`/api/application/${applicationId}`, () => { navigate('/admin/application') })
  const getColor = (text) => {
    switch (text) {
      case 'Verified: Yes':
      case 'accepted':
        return 'text-status-success'
      case 'Verified: No':
      case 'rejected':
        return 'text-status-error'
      case 'Needs Verification':
      case 'escalated':
        return 'text-status-warning'
      default:
        return null
    }
  }

  if (
    application != null &&
    userInfo != null
  ) {
    const hasStatuses = application.statuses.length > 0
    const currentStatus = hasStatuses && application.statuses[0].status
    const canDelete = userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Delete Application')) != null
    const canReject = userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Reject Application')) != null

    return (
      <RadAppLayout
        name={application.id}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={confirmResend}>Resend Confirmation Email</RadButton>
                <RadButton
                  onClick={() => navigate('edit')}
                  disabled={currentStatus === 'accepted'}
                >
                  Edit
                </RadButton>
                {canReject && currentStatus === 'escalated' &&
                  <RadButton onClick={confirmReject}>
                    Reject
                  </RadButton>}
                {canDelete && (application.statuses.length === 0) &&
                  <RadButton onClick={confirmDelete}>
                    Delete
                  </RadButton>}
              </RadSpaceBetween>
            }
          >
            Application # {application.id}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Eligibility Criteria
                </RadHeader>
              }
            >
              <p>
                To be eligible for this program, a child must have been at least three years old on 9/1/23 (the minimum age for Head Start), attend school within the Muscogee Nation, and meet at least one of the following criteria:
              </p>
              <p>
                &#8226; Live in a household which receives SNAP, TANF, FDPIR, and/or Medicaid.
                <br />
                &#8226; Be a foster, homeless migrant, runaway, or Head Start child.
                <br />
                &#8226; Attend a NSLP or SBP participating or special provision school and live in a household with income equal to or less than reduced price lunch guideline.
              </p>
            </RadContainer>

            {/* General Information */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  General Information
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={2} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Created
                    </RadBox>
                    <RadSpaceBetween size='xxs'>
                      <div>{formatDateTime(application.createdAt)} {application.signature}</div>
                      {application.consentToApplyOnBehalfOf != null && <div>Consent to apply on behalf of applicant: {application.consentToApplyOnBehalfOf ? 'Yes' : 'No'}</div>}
                    </RadSpaceBetween>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Updated
                    </RadBox>
                    <RadSpaceBetween size='xxs'>
                      {application.updatedAt === application.createdAt && <div>-</div>}
                      {application.updatedAt !== application.createdAt &&
                        <div>{formatDateTime(application.updatedAt)} - {application.signature2}</div>}
                      {application.consentToEditOnBehalfOf != null && <div>Consent to edit on behalf of applicant: {application.consentToEditOnBehalfOf ? 'Yes' : 'No'}</div>}
                    </RadSpaceBetween>
                  </div>
                </RadColumnLayout>
                <RadColumnLayout columns={2} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Status
                    </RadBox>
                    {!hasStatuses && <div>Submitted</div>}
                    {hasStatuses &&
                      <RadBox display='inline' color={getColor(application.statuses[0]?.status)}>
                        {toTitleCase(application.statuses[0].status)}{application.statuses[0].ebtProvider != null && ` - ${application.statuses[0].ebtProvider}`} {application.statuses[0].reason != null && ` - ${application.statuses[0].reason}`}
                        <br />
                        {formatDateTime(application.statuses[0].createdAt)}
                      </RadBox>}
                    <br />
                    {application.bypassMailingAddress && <div>Bypassed Mailing Address</div>}
                    {application.bypassPotentialDuplicate && <div>Bypassed Potential Duplicate</div>}
                    {application.schoolDistrictOverride != null && <div>School District Override: {application.schoolDistrictOverride}</div>}
                  </div>
                  {hasStatuses && application.statuses[0].status === 'accepted' &&
                    <div>
                      <RadSpaceBetween size='xxs'>
                        <RadBox variant='awsui-key-label'>
                          Eligible Children <RadBox display='inline' color='text-status-inactive'>({Object.keys(application.statuses[0].eligibilityReasons).length}/{application.children.length})</RadBox>
                        </RadBox>
                        <RadSpaceBetween size='xxs'>
                          {Object
                            .keys(application.statuses[0].eligibilityReasons)
                            .map(x => {
                              const child = application.children.find(y => y.id.toString() === x)
                              child.reason = application.statuses[0].eligibilityReasons[x].join('. ')
                              const childVerificationStatus = child.verified === true ? 'Verified: Yes' : child.verified === false ? 'Verified: No' : 'Needs Verification'
                              const childAudit = (() => {
                                if (child.verified !== false) { return null }
                                const { statusColor, displayName, nodes } = states[child.auditState]
                                return <>
                                  <RadBox fontWeight='heavy' color={statusColor}>Audit status: {displayName}</RadBox>
                                  {nodes(child)}
                                </>
                              })()
                              return (
                                <div key={`ec-${x}`}>
                                  {`${child.firstName} ${child.middleName ?? ''} ${child.lastName} ${child.suffix ?? ''}`} - {child.reason}
                                  <RadBox color={getColor(childVerificationStatus)}>{childVerificationStatus}</RadBox>
                                  {childAudit}
                                </div>
                              )
                            })}
                        </RadSpaceBetween>
                      </RadSpaceBetween>
                    </div>}
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>

            {/* Primary Contact Information */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Primary Contact Information
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Name
                  </RadBox>
                  <div>{`${application.primaryContact.firstName} ${application.primaryContact.middleName ? application.primaryContact.middleName + ' ' : ''} ${application.primaryContact.lastName} ${application.primaryContact.suffix ?? ''}`}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Race / Ethnicity
                  </RadBox>
                  <div>{application.primaryContact.raceEthnicity ?? '-'}</div>
                </div>
                {application.primaryContact.tribe != null &&
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Tribe
                    </RadBox>
                    <div>{application.primaryContact.tribe}</div>
                  </div>}
                <div>
                  <RadBox variant='awsui-key-label'>
                    Birth Date
                  </RadBox>
                  <div>{formatDate(application.primaryContact.birthDate)}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Mailing Address
                  </RadBox>
                  <div>{application.primaryContact.mailingAddress.line1}</div>
                  <div>{application.primaryContact.mailingAddress.line2}</div>
                  <div>{application.primaryContact.mailingAddress.city}, {application.primaryContact.mailingAddress.state} {application.primaryContact.mailingAddress.zip}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Physical Address
                  </RadBox>
                  <div>{application.primaryContact.physicalAddress.line1}</div>
                  <div>{application.primaryContact.physicalAddress.line2}</div>
                  <div>{application.primaryContact.physicalAddress.city}, {application.primaryContact.physicalAddress.state} {application.primaryContact.physicalAddress.zip}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Email
                  </RadBox>
                  <div>{application.primaryContact.email ? <RadLink href={`mailto:${application.primaryContact.email}`}>{application.primaryContact.email}</RadLink> : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Phone(s)
                  </RadBox>
                  <div>{application.primaryContact.phone1.number} - {application.primaryContact.phone1.type}</div>
                  {application.primaryContact.phone2 != null && <div>{application.primaryContact.phone2?.number} - {application.primaryContact.phone2?.type}</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Consent to be Contacted
                  </RadBox>
                  <div>{application.primaryContact.consentToContact ? 'Yes' : 'No'}</div>
                </div>
                {application.primaryContact.preferredContactMethod != null &&
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Preferred Contact Method
                    </RadBox>
                    <div>{application.primaryContact.preferredContactMethod}</div>
                  </div>}
                <div>
                  <RadBox variant='awsui-key-label'>
                    Member of Household / Role
                  </RadBox>
                  <div>{application.primaryContact.householdMember ? `Yes - ${application.primaryContact.role}` : 'No'}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>

            {/* Secondary Contact Information */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Secondary Contact Information
                </RadHeader>
              }
            >
              {application.secondaryContact == null && <RadBox color='text-status-inactive'>No secondary contact information provided.</RadBox>}
              {application.secondaryContact != null &&
                <RadColumnLayout columns={3} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Name
                    </RadBox>
                    <div>{`${application.secondaryContact.firstName} ${application.secondaryContact.middleName ? application.secondaryContact.middleName + ' ' : ''} ${application.secondaryContact.lastName} ${application.secondaryContact.suffix ?? ''}`}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Email
                    </RadBox>
                    <div>{application.secondaryContact.email ? <RadLink href={`mailto:${application.secondaryContact.email}`}>{application.secondaryContact.email}</RadLink> : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Phone
                    </RadBox>
                    <div>{application.secondaryContact.phone?.number} - {application.secondaryContact.phone?.type}</div>
                  </div>
                </RadColumnLayout>}
            </RadContainer>

            {/* Household Information */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Household Information
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Primary Language Spoken
                  </RadBox>
                  <div>{application.household.primaryLanguageSpoken}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Program(s)
                  </RadBox>
                  <div>{application.household.snap && 'SNAP'}</div>
                  <div>{application.household.tanf && 'TANF'}</div>
                  <div>{application.household.fdpir && 'FDPIR'}</div>
                  <div>{application.household.medicaid && 'Medicaid'}</div>
                  <div>{!application.household.snap && !application.household.tanf && !application.household.fdpir && !application.household.medicaid && '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Income
                  </RadBox>
                  <div>{formatCurrency(application.household.incomeAmount)} / {application.household.incomeFrequency}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    PIN
                  </RadBox>
                  <div>{application.household.pin ?? '-'}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>

            {/* Child Information */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Child Information
                </RadHeader>
              }
            >
              {application.children.map((child, index) => (
                <div key={'child-' + child.id}>
                  {index > 0 && <br />}
                  {index > 0 && <RadDivider />}
                  <RadColumnLayout columns={4} borders='vertical'>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Name
                      </RadBox>
                      <div>{`${child.firstName} ${child.middleName ? child.middleName + ' ' : ''} ${child.lastName} ${child.suffix ?? ''}`}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Race / Ethnicity
                      </RadBox>
                      <div>{child.raceEthnicity ?? '-'}</div>
                    </div>
                    {child.tribe != null &&
                      <div>
                        <RadBox variant='awsui-key-label'>
                          Tribe
                        </RadBox>
                        <div>{child.tribe}</div>
                      </div>}
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Birth Date
                      </RadBox>
                      <div>{formatDate(child.birthDate)}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Grade
                      </RadBox>
                      <div>{child.grade}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        School
                      </RadBox>
                      {child.schoolDistrict != null && <div>{child.schoolDistrict.name} SCHOOL DISTRICT<br />{child.schoolDistrict.county != null ? `${child.schoolDistrict.county} COUNTY` : ''}</div>}
                      {child.homeSchool && <div>Home School</div>}
                      {child.epicCharterSchool && <div>Charter School</div>}
                      {child.otherSchool && <div>Other</div>}
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Foster Child
                      </RadBox>
                      <div>{child.fosterChild ? 'Yes' : 'No'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Homeless / Migrant / Runaway
                      </RadBox>
                      <div>{child.homelessMigrantRunaway ? 'Yes' : 'No'}</div>
                    </div>
                    <div>
                      {child.excluded && <RadBox color='text-status-error'>Excluded from benefit recipients</RadBox>}
                    </div>
                  </RadColumnLayout>
                </div>
              ))}
            </RadContainer>

            {/* Household Member Information */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Household Member Information
                </RadHeader>
              }
            >
              {application.householdMembers.length === 0 && <RadBox color='text-status-inactive'>No other household members provided.</RadBox>}
              {application.householdMembers.map((member, index) => (
                <div key={'member-' + member.id}>
                  {index > 0 && <br />}
                  {index > 0 && <RadDivider />}
                  <RadColumnLayout columns={3} borders='vertical'>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Name
                      </RadBox>
                      <div>{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''} ${member.lastName} ${member.suffix ?? ''}`}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Race / Ethnicity
                      </RadBox>
                      <div>{member.raceEthnicity ?? '-'}</div>
                    </div>
                    {member.tribe != null &&
                      <div>
                        <RadBox variant='awsui-key-label'>
                          Tribe
                        </RadBox>
                        <div>{member.tribe}</div>
                      </div>}
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Birth Date
                      </RadBox>
                      <div>{formatDate(member.birthDate)}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Role
                      </RadBox>
                      <div>{member.role}</div>
                    </div>
                  </RadColumnLayout>
                </div>
              ))}
            </RadContainer>

            {/* eSignature(s) */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  eSignature(s)
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <div className='signature-container'>
                  <div className='signature' style={{ lineHeight: '38px' }}>{application.signature ?? ''}</div>
                  <div className='date'>{formatDateTime(application.createdAt)}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    I agree that my electronic signature is the legal equivalent of my handwritten signature on this document
                  </RadBox>
                  <div>{application.agreeEsignature === true ? 'Yes' : 'No'}</div>
                </div>
              </RadSpaceBetween>
              {application.signature2 != null &&
                <>
                  <RadDivider />
                  <RadSpaceBetween size='l'>
                    <div className='signature-container'>
                      <div className='signature' style={{ lineHeight: '38px' }}>{application.signature2 ?? ''}</div>
                      <div className='date'>{formatDateTime(application.updatedAt)}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        I agree that my electronic signature is the legal equivalent of my handwritten signature on this document
                      </RadBox>
                      <div>{application.agreeEsignature2 === true ? 'Yes' : 'No'}</div>
                    </div>
                  </RadSpaceBetween>
                </>}
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
