import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'

if (module.hot) {
  console.log('🔥 Hot Reload 🔥')
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App />
)
