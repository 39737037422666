
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1612d_14vz8_179",
  "spinner-rotator": "awsui_spinner-rotator_1612d_14vz8_1",
  "size-normal": "awsui_size-normal_1612d_14vz8_194",
  "size-big": "awsui_size-big_1612d_14vz8_202",
  "size-large": "awsui_size-large_1612d_14vz8_210",
  "variant-normal": "awsui_variant-normal_1612d_14vz8_218",
  "variant-disabled": "awsui_variant-disabled_1612d_14vz8_221",
  "variant-inverted": "awsui_variant-inverted_1612d_14vz8_224",
  "circle": "awsui_circle_1612d_14vz8_236",
  "circle-left": "awsui_circle-left_1612d_14vz8_264",
  "spinner-line-left": "awsui_spinner-line-left_1612d_14vz8_1",
  "circle-right": "awsui_circle-right_1612d_14vz8_269",
  "spinner-line-right": "awsui_spinner-line-right_1612d_14vz8_1"
};
  