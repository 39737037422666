import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadModal } from '../common/RadModal'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadLink } from '../common/RadLink'
import { formatDate, formatDateTime } from '../common/utilities'

export function PassiveEnrollmentDetail () {
  const { passiveEnrollmentId } = useParams()
  const [reloadCounter, setReloadCounter] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [formValues, setFormValues] = useState()
  const { data: passiveEnrollment } = useGet(`/api/passive-enrollment/${passiveEnrollmentId}?reload=${reloadCounter}`, true)
  const { data: userInfo } = useGet('/api/user/current')
  const confirmReject = useConfirm('Reject Passive Enrollment?', 'Please confirm you would like to reject the passive enrollment?', 'Reject', () => { reject() })
  const reject = usePost(`/api/passive-enrollment/reject/${passiveEnrollmentId}`, {}, () => { setReloadCounter(reloadCounter + 1) })
  const admin = usePost(`/api/passive-enrollment/admin/${passiveEnrollmentId}`, formValues, (resp) => { setShowModal(false); setReloadCounter(reloadCounter + 1) })

  useEffect(() => {
    if (passiveEnrollment != null) {
      setFormValues({ ...passiveEnrollment, declined: passiveEnrollment.declinedAt != null })
    }
  }, [passiveEnrollment])

  const getColor = (item) => {
    switch (item?.status) {
      case 'accepted':
        return 'text-status-success'
      case 'rejected':
        return 'text-status-error'
      case 'escalated':
        return 'text-status-warning'
      case 'declined':
        return 'text-status-warning'
      default:
        return null
    }
  }

  if (
    passiveEnrollment != null &&
    formValues != null &&
    userInfo != null
  ) {
    const currentStatus = passiveEnrollment.declinedAt != null ? { status: 'declined', updatedAt: passiveEnrollment.updatedAt } : passiveEnrollment.statuses[0] ?? { status: 'submitted', updatedAt: passiveEnrollment.createdAt }
    const canReject = userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Reject Passive Enrollment')) != null
    const canDecline = userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Decline Passive Enrollment')) != null
    const isDirty = formValues.declined !== (passiveEnrollment.declinedAt != null) || formValues.bypassPotentialDuplicate !== passiveEnrollment.bypassPotentialDuplicate
    const makeSearchTarget = (arr) => encodeURIComponent(arr.filter(s => s != null).join(' '))

    return (
      <RadAppLayout
        name={passiveEnrollment.id}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                {canReject && (currentStatus.status === 'escalated') &&
                  <RadButton onClick={confirmReject}>
                    Reject
                  </RadButton>}
                <RadButton
                  onClick={() => setShowModal(true)}
                  disabled={currentStatus.status === 'accepted' || (currentStatus.status !== 'escalated' && !canDecline)}
                >
                  Admin Controls
                </RadButton>
              </RadSpaceBetween>
            }
          >
            Passive Enrollment # {passiveEnrollment.id}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  General Information
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={2} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Created
                    </RadBox>
                    {formatDateTime(passiveEnrollment.createdAt)}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Status
                    </RadBox>
                    <RadSpaceBetween size='s'>
                      <RadBox display='inline' color={getColor(currentStatus)}>
                        {toTitleCase(currentStatus.status)} {currentStatus.reason != null && ` - ${currentStatus.reason}`}
                        <br />
                        {formatDateTime(currentStatus.updatedAt)}
                      </RadBox>
                      {passiveEnrollment.bypassPotentialDuplicate && <div>Bypassed Potential Duplicate</div>}
                    </RadSpaceBetween>
                  </div>
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>

            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Student Testing ID
                  </RadBox>
                  <div>{passiveEnrollment.studentTestingNumber}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Student Name
                  </RadBox>
                  <div>{`${passiveEnrollment.studentFirstName} ${passiveEnrollment.studentMiddleName ? passiveEnrollment.studentMiddleName + ' ' : ''} ${passiveEnrollment.studentLastName}`}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Student Birth Date
                  </RadBox>
                  <div>{formatDate(passiveEnrollment.studentBirthdate)}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Student Grade
                  </RadBox>
                  <div>{passiveEnrollment.grade}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    School
                  </RadBox>
                  <div>{passiveEnrollment.schoolName}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    District
                  </RadBox>
                  <div>{passiveEnrollment.districtName}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Guardian Name
                  </RadBox>
                  <RadLink href={`/admin/passive-enrollment?search=${makeSearchTarget([passiveEnrollment.guardianFirstName, passiveEnrollment.guardianMiddleName, passiveEnrollment.guardianLastName])}`}>
                    <div>{`${passiveEnrollment.guardianFirstName} ${passiveEnrollment.guardianMiddleName ? passiveEnrollment.guardianMiddleName + ' ' : ''} ${passiveEnrollment.guardianLastName}`}</div>
                  </RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Mailing Address
                  </RadBox>
                  <RadLink href={`/admin/passive-enrollment?search=${makeSearchTarget([passiveEnrollment.mailingAddressLine1, passiveEnrollment.mailingAddressLine2, passiveEnrollment.mailingAddressCity, passiveEnrollment.mailingAddressState, passiveEnrollment.mailingAddressZip])}`}>
                    <div>{passiveEnrollment.mailingAddressLine1}</div>
                    <div>{passiveEnrollment.mailingAddressLine2}</div>
                    <div>{passiveEnrollment.mailingAddressCity} {passiveEnrollment.mailingAddressState} {passiveEnrollment.mailingAddressZip}</div>
                  </RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Physical Address
                  </RadBox>
                  {passiveEnrollment.physicalAddressLine1 == null &&
                    passiveEnrollment.physicalAddressLine2 == null &&
                    passiveEnrollment.physicalAddressCity == null &&
                    passiveEnrollment.physicalAddressState == null &&
                    passiveEnrollment.physicalAddressZip == null && '-'}
                  <div>{passiveEnrollment.physicalAddressLine1}</div>
                  <div>{passiveEnrollment.physicalAddressLine2}</div>
                  <div>{passiveEnrollment.physicalAddressCity}{passiveEnrollment.physicalAddressCity != null && ','} {passiveEnrollment.physicalAddressState} {passiveEnrollment.physicalAddressZip}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Household Phones
                  </RadBox>
                  <div>
                    {passiveEnrollment.householdPhones.map((x, i) =>
                      <RadLink key={'phone-' + i} href={`/admin/passive-enrollment?search=${makeSearchTarget([x])}`}>
                        <div>{x}</div>
                      </RadLink>
                    )}
                    {passiveEnrollment.householdPhones.length === 0 && '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Household Emails
                  </RadBox>
                  <div>
                    {passiveEnrollment.householdEmails.map((x, i) => <div key={'email-' + i}>{x}</div>)}
                    {passiveEnrollment.householdEmails.length === 0 && '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Language
                  </RadBox>
                  <div>{passiveEnrollment.language ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Preferred Contact Method
                  </RadBox>
                  <div>{passiveEnrollment.prefContactMethod ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Certification Method
                  </RadBox>
                  <div>{passiveEnrollment.certificationMethod ?? '-'}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadModal
              size='large'
              onDismiss={() => {
                setShowModal(false)
              }}
              visible={showModal}
              header='Admin Controls'
              footer={
                <RadBox float='right'>
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadButton
                      onClick={() => setShowModal(false)}
                      variant='link'
                    >
                      Cancel
                    </RadButton>
                    <RadButton
                      onClick={() => admin()}
                      variant='primary'
                      disabled={!isDirty}
                    >
                      Save Changes
                    </RadButton>
                  </RadSpaceBetween>
                </RadBox>
              }
            >
              {formValues != null &&
                <RadForm>
                  <RadSpaceBetween size='s'>
                    <RadFormField>
                      <RadCheckbox
                        checked={formValues.declined}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, declined: detail.checked })
                        }}
                      >
                        Parent / Guardian declined benefits
                      </RadCheckbox>
                    </RadFormField>
                    {currentStatus.status === 'escalated' &&
                      <RadFormField>
                        <RadCheckbox
                          checked={formValues.bypassPotentialDuplicate}
                          onChange={({ detail }) => {
                            setFormValues({ ...formValues, bypassPotentialDuplicate: detail.checked })
                          }}
                        >
                          Passive Enrollment is not a potential duplicate
                        </RadCheckbox>
                      </RadFormField>}
                  </RadSpaceBetween>
                </RadForm>}
            </RadModal>
          </RadSpaceBetween>
        }
      />
    )
  }
}
