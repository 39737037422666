import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePut } from '../hooks/usePut'
import { useConfirm } from '../hooks/useConfirm'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadSelect } from '../common/RadSelect'
import { RadButton } from '../common/RadButton'
import { RadBox } from '../common/RadBox'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function OverrideEditor ({ formValues, setFormValues, translate, showBypassMailingAddress, nodes }) {
  const ebtProviderOptions = [
    { value: '', label: '-' },
    { value: 'Cherokee', label: 'Cherokee' },
    { value: 'Chickasaw', label: 'Chickasaw' }
  ]

  const bypassMailingAddress = <RadFormField field='bypassMailingAddress'>
    <RadCheckbox
      checked={formValues.bypassMailingAddress ?? false}
      onChange={({ detail }) => {
        setFormValues({ ...formValues, bypassMailingAddress: detail.checked })
      }}
    >
      Mailing address outside of Oklahoma, application should proceed.
    </RadCheckbox>
  </RadFormField>

  const bypassPotentialDuplicate = <RadFormField field='bypassPotentialDuplicate'>
    <RadCheckbox
      checked={formValues.bypassPotentialDuplicate ?? false}
      onChange={({ detail }) => {
        setFormValues({ ...formValues, bypassPotentialDuplicate: detail.checked })
      }}
    >
      Application is not a potential duplicate.
    </RadCheckbox>
  </RadFormField>

  const schoolDistrictOverride = <RadFormField label='EBT Provider' field='schoolDistrictOverride'>
    <RadSelect
      filteringType='none'
      selectedOption={ebtProviderOptions.find(x => x.value === formValues.schoolDistrictOverride) ?? ebtProviderOptions[0]}
      onChange={({ detail }) => {
        if (detail.selectedOption.value === '') {
          setFormValues({ ...formValues, schoolDistrictOverride: null })
        } else {
          setFormValues({ ...formValues, schoolDistrictOverride: detail.selectedOption.value })
        }
      }}
      options={ebtProviderOptions}
      enteredTextLabel={value => value}
      selectedAriaLabel='Selected'
      placeholder='Choose a EBT provider'
      empty='No matches found'
    />
  </RadFormField>

  return (
    <RadContainer header={<RadHeader variant='h2'>{translate('Admin Controls')}</RadHeader>}>
      <RadSpaceBetween size='s'>
        {showBypassMailingAddress && bypassMailingAddress}
        {formValues.id != null && bypassPotentialDuplicate}
        {schoolDistrictOverride}
        {nodes}
      </RadSpaceBetween>
    </RadContainer>
  )
}

export function OverrideEditorForm ({ application, translate }) {
  const originalValues = {
    id: application.id,
    bypassPotentialDuplicate: application.bypassPotentialDuplicate,
    schoolDistrictOverride: application.schoolDistrictOverride
  }
  const [formValues, setFormValues] = useState(originalValues)
  const navigate = useNavigate()
  const update = usePut(`/api/application/${application.id}/controls`, formValues, (resp) => { navigate(`/admin/application/${application.id}`) })
  const confirmUpdate = useConfirm(
    'Confirm Save Changes',
    'Submitting these changes will remove the application\'s current status. Are you sure you want to proceed?',
    'Save Changes',
    () => { update() }
  )
  const currentStatus = application.statuses[0]?.status
  const isPristine = () => Object.keys(formValues).every(k => formValues[k] === originalValues[k])

  const saveButton = <RadButton
    onClick={() => {
      if (application.statuses.length > 0) {
        confirmUpdate()
      } else {
        update()
      }
    }}
    formAction='submit'
    variant='normal'
    disabled={currentStatus === 'accepted' || isPristine()}
  >
    Save Changes
  </RadButton>

  return <RadBox padding={{ bottom: 'xxl' }}>
    <OverrideEditor
      formValues={formValues}
      setFormValues={setFormValues}
      translate={translate}
      showBypassMailingAddress={false}
      nodes={saveButton}
    />
  </RadBox>
}
