
    import './styles.scoped.css';
    export default {
  "expandable-toggle-wrapper": "awsui_expandable-toggle-wrapper_c6tup_izfe5_141",
  "body-cell": "awsui_body-cell_c6tup_izfe5_148",
  "body-cell-content": "awsui_body-cell-content_c6tup_izfe5_156",
  "expandable-level-0": "awsui_expandable-level-0_c6tup_izfe5_162",
  "expandable-level-1": "awsui_expandable-level-1_c6tup_izfe5_169",
  "expandable-level-2": "awsui_expandable-level-2_c6tup_izfe5_176",
  "expandable-level-3": "awsui_expandable-level-3_c6tup_izfe5_183",
  "expandable-level-4": "awsui_expandable-level-4_c6tup_izfe5_190",
  "expandable-level-5": "awsui_expandable-level-5_c6tup_izfe5_197",
  "expandable-level-6": "awsui_expandable-level-6_c6tup_izfe5_204",
  "expandable-level-7": "awsui_expandable-level-7_c6tup_izfe5_211",
  "expandable-level-8": "awsui_expandable-level-8_c6tup_izfe5_218",
  "expandable-level-9": "awsui_expandable-level-9_c6tup_izfe5_225",
  "expandable-level-next": "awsui_expandable-level-next_c6tup_izfe5_232",
  "body-cell-wrap": "awsui_body-cell-wrap_c6tup_izfe5_253",
  "is-visual-refresh": "awsui_is-visual-refresh_c6tup_izfe5_267",
  "has-striped-rows": "awsui_has-striped-rows_c6tup_izfe5_279",
  "body-cell-edit-active": "awsui_body-cell-edit-active_c6tup_izfe5_362",
  "body-cell-interactive": "awsui_body-cell-interactive_c6tup_izfe5_362",
  "body-cell-editable": "awsui_body-cell-editable_c6tup_izfe5_362",
  "has-striped-rows-sticky-cell-pad-inline-start": "awsui_has-striped-rows-sticky-cell-pad-inline-start_c6tup_izfe5_528",
  "has-selection": "awsui_has-selection_c6tup_izfe5_611",
  "body-cell-align-top": "awsui_body-cell-align-top_c6tup_izfe5_701",
  "body-cell-first-row": "awsui_body-cell-first-row_c6tup_izfe5_704",
  "body-cell-last-row": "awsui_body-cell-last-row_c6tup_izfe5_707",
  "body-cell-selected": "awsui_body-cell-selected_c6tup_izfe5_707",
  "has-footer": "awsui_has-footer_c6tup_izfe5_707",
  "body-cell-shaded": "awsui_body-cell-shaded_c6tup_izfe5_717",
  "sticky-cell": "awsui_sticky-cell_c6tup_izfe5_747",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_c6tup_izfe5_755",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_c6tup_izfe5_852",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_c6tup_izfe5_861",
  "body-cell-next-selected": "awsui_body-cell-next-selected_c6tup_izfe5_893",
  "body-cell-prev-selected": "awsui_body-cell-prev-selected_c6tup_izfe5_900",
  "body-cell-editor-wrapper": "awsui_body-cell-editor-wrapper_c6tup_izfe5_926",
  "body-cell-success": "awsui_body-cell-success_c6tup_izfe5_931",
  "body-cell-editor": "awsui_body-cell-editor_c6tup_izfe5_926",
  "body-cell-editor-disabled": "awsui_body-cell-editor-disabled_c6tup_izfe5_956",
  "body-cell-editor-form": "awsui_body-cell-editor-form_c6tup_izfe5_965",
  "body-cell-editor-row": "awsui_body-cell-editor-row_c6tup_izfe5_975",
  "body-cell-editor-controls": "awsui_body-cell-editor-controls_c6tup_izfe5_985",
  "body-cell-editor-row-editor": "awsui_body-cell-editor-row-editor_c6tup_izfe5_988",
  "body-cell-expandable": "awsui_body-cell-expandable_c6tup_izfe5_991",
  "resizable-columns": "awsui_resizable-columns_c6tup_izfe5_1012",
  "expandable-cell-content": "awsui_expandable-cell-content_c6tup_izfe5_1015",
  "body-cell-has-success": "awsui_body-cell-has-success_c6tup_izfe5_1084",
  "body-cell-edit-disabled-popover": "awsui_body-cell-edit-disabled-popover_c6tup_izfe5_1084"
};
  