import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RadBox } from './common/RadBox'

export function Confirmation () {
  const [searchParams] = useSearchParams()
  const language = searchParams.get('language')
  const number = searchParams.get('number')
  const uuid = searchParams.get('uuid')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  switch (language) {
    case 'Spanish':
      return (
        <RadBox padding='l'>
          <h1>Solicitud Enviada con Éxito: Oklahoma Summer EBT</h1>
          <h3>
            Su solicitud ha sido enviada con éxito para su revisión. Su número de confirmación es #{number}.<br />
            Recibirá una notificación por correo electrónico cuando se haya realizado una determinación de elegibilidad para su solicitud.
            <br /><br />
            Haga clic <a href={`/view/${uuid}`}>aquí</a> para ver / editar su solicitud.
          </h3>
        </RadBox>
      )
    default:
      return (
        <RadBox padding='l'>
          <h1>Application Submitted Successfully: Oklahoma Summer EBT</h1>
          <h3>
            Your application has successfully been submitted for review. Your confirmation number is #{number}.<br />
            You will receive an email notification when an eligibility determination has been made for your application.
            <br /><br />
            Click <a href={`/view/${uuid}`}>here</a> to view / edit your application.
          </h3>
        </RadBox>
      )
  }
}
