import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'
import { Avatar } from '@rmwc/avatar'

export function UserList () {
  const navigate = useNavigate()
  const { data: users, count } = useGet('/api/user')

  if (users) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Users
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <>
                  <Avatar
                    src={item.photoUrl}
                    size='large'
                    name={item.name}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <RadLink fontSize='heading-m' href={`/admin/user/${item.id}`}>{item.name}</RadLink>
                </>
              ),
              sections: [
                {
                  id: 'details',
                  content: item => {
                    return (
                      <>
                        {item.title && <div>{item.title}</div>}
                        {item.email && <div>{item.email}</div>}
                        {item.phone && <div>{item.phone}</div>}
                      </>
                    )
                  }
                },
                {
                  id: 'roles',
                  header: 'Roles',
                  content: item => item.roles?.length > 0
                    ? <>{item.roles.map((role) => { return <div key={role.id}><RadLink href={`/role/${role.id}`}>{role.name}</RadLink></div> })}</>
                    : <div>-</div>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={users ?? []}
            variant='full-page'
          />
        }
      />
    )
  }
}
