import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../App'
import { translations } from '../translations'
import '../form.css'
import '../layout.css'
import '../responsive.css'

export function ChickasawNationBenefits () {
  const [isOpen, setIsOpen] = useState(false)
  const { language, setLanguage } = useContext(AppContext)

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.warn('No translation found for', text)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  const links = (
    <>
      <li><Link to='/' role='button'>{translate('HOME')}</Link></li>
      <li><Link to='/apply' role='button'>{translate('APPLICATION')}</Link></li>
      <li><Link to='/cherokee-nation-benefits' role='button'>{translate('CHEROKEE NATION BENEFITS')}</Link></li>
      <li>{translate('CHICKASAW NATION BENEFITS')}</li>
      <li>
        <select
          onChange={(event) => {
            setLanguage(event.target.value)
            setIsOpen(false)
          }}
          id='language-menu'
          ariay-labelledby='language-menu-label'
          name='language-menu'
          value={language}
        >
          <option value='English'>{translate('ENGLISH')}</option>
          <option value='Spanish'>{translate('SPANISH')}</option>
        </select>
      </li>
    </>
  )

  return (
    <div>
      <section className='main_div main_menu_wrap menu_section_wrap'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 mnu_top text-center'>
              <ul className='web_nav'>
                {links}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className='responsive_header white-background' id='back_top_menu'>
        <div className='container-fluid px-lg-5'>
          <div className='row pding align-items-center'>
            <div className='col-8 ps-5' />
            <div className='mobile_icon_wrap col-4' onClick={() => setIsOpen(!isOpen)}>
              <div className='mobile_icon'>
                <i className='fas fa-bars' aria-hidden='true' />
              </div>
            </div>
          </div>
        </div>
        <ul id={isOpen ? 'open_nav' : null} className='top_menu responsive_header_nav pt-5 web_nav_wrap'>
          {links}
        </ul>
      </div>
      <div className='menu_holder' />

      <section className='main_div index_banner_wrap'>
        <div className='back_img_wrap'>
          <img src='assets/images/index_banner_converted.webp' alt='man holding small child' />
        </div>
        <div className='container banner_main_wrap'>
          <div className='row'>
            <div className='col-lg-8 ms-auto'>
              <div className='banner_content_wrap'>
                <h1>
                  {translate('CHICKASAW NATION')}
                  <br />
                  {translate('BENEFITS')}
                </h1>
                <p>{translate('Information about the items eligible for purchase with Summer EBT benefits and participating retailers can be found at the links below.')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='main_div index_benefits_wrap'>
        <div className='container-fluid pt-lg-5 px-lg-5'>
          <div className='row align-items-center px-lg-5'>
            <h2 style={{ whiteSpace: 'nowrap' }}>{translate('LINKS')}</h2>
            <br />
            <br />
            <br />
            <br />
            <p>
              <a href='FAQs English 2024 Creek Approved.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('FAQ')}
              </a>
            </p>
            <p>
              <a href='SEBT FAQ Chickasaw SPANISH.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('FAQ')} - {translate('Spanish')}
              </a>
            </p>
            <p>
              <a href='SEBT Helpful Hints Chickasaw.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Helpful Hints')}
              </a>
            </p>
            <p>
              <a href='SEBT Helpful Hints Chickasaw SPANISH.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Helpful Hints')} - {translate('Spanish')}
              </a>
            </p>
            <p>
              <a href='2024 Larger Food Card Creek.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Food Card')}
              </a>
            </p>
            <p>
              <a href='WICShopper-App-Flyer-English.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('WIC Shopper App Flyer')}
              </a>
            </p>
            <p>
              <a href='Muscogee (Creek) Nation Grocery Store V2 approved.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Approved Stores')}
              </a>
            </p>
          </div>
        </div>
      </section>

      <footer className='main_div footer_wrap'>
        <div className='container-fluid px-lg-5'>
          <ul className='footer_web_nav'>
            {links}
          </ul>
          <div className='row '>
            <div className='col-lg-4 first_info_para'>
              <p>{translate('In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, this institution is prohibited from discriminating on the basis of race, color, national origin, sex (including gender identity and sexual orientation), disability, age, or reprisal or retaliation for prior civil rights activity.  This institution is an equal opportunity provider')}.</p>
            </div>
            <div className='col-lg-4  contact_info_wrap'>
              <h3>{translate('Contact Info')}</h3>
              <div className='row justify-content-center '>
                <div className='col-lg-4 text-center px-0 mx-0'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0 mx-0'>

                  <p>{translate('For assistance or to apply over the phone please call the')} <b>{translate('Muscogee')}</b> {translate('Summer EBT Helpline')}</p>
                  <p><a href='tel:918-213-4855' target='_blank' rel='noreferrer'>918-213-4855 </a></p>
                  <p>{translate('9AM to 6PM Monday through Friday')}<br />{translate('or 9AM to 5PM Saturday')}.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 calling_wrap contact_info_wrap'>
              <div className='row '>
                <div className='col-lg-4 pe-0 text-center'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0'>
                  <p>{translate('If you are calling about a')} <b>{translate('Cherokee Nation')}</b> {translate('Summer EBT application, please call')}</p>
                  <p className=''><a href='tel:539-234-3265' role='button'>539-234-3265</a></p>
                </div>
                <div className='col-lg-4 text-center pe-0'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0'>
                  <p>{translate('If you are calling about a')} <b>{translate('Chickasaw Nation')}</b> {translate('Summer EBT application, please call')}</p>
                  <p><a href='tel:844-256-3467' role='button'>844-256-3467</a><span> or </span><a href='tel: 580-272-1178' role='button'>580-272-1178</a></p>
                </div>
              </div>
            </div>
            <p><a className='white' href='/admin' role='button'>{translate('Administrator Access')}</a></p>
          </div>
        </div>
      </footer>
    </div>
  )
}
