import React from 'react';
  const icons = {
  "add-plus": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8 1v14M15 8H1"/></svg>,
"anchor-link": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M11 8H5" className="stroke-linecap-round"/><path d="M7 4H5a4 4 0 0 0 0 8h2M9 4h2a4 4 0 0 1 0 8H9"/></svg>,
"angle-down": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m1 4 7 7 7-7"/></svg>,
"angle-left-double": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M14 1 7 8l7 7"/><path d="M9 1 2 8l7 7"/></svg>,
"angle-left": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M12 1 5 8l7 7"/></svg>,
"angle-right-double": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m2 1 7 7-7 7"/><path d="m7 1 7 7-7 7"/></svg>,
"angle-right": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m4 1 7 7-7 7"/></svg>,
"angle-up": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m1 12 7-7 7 7"/></svg>,
"arrow-left": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 1 2 8l7 7M2 8h13"/></svg>,
"arrow-right": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 8h13M7 1l7 7-7 7"/></svg>,
"audio-full": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7 15V1L1 6v4l6 5ZM15 3v10M11 5v6" className="stroke-linejoin-round"/></svg>,
"audio-half": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7 15V1L1 6v4l6 5ZM11 5v6" className="stroke-linejoin-round"/></svg>,
"audio-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7 15V1L1 6v4l6 5Z" className="stroke-linejoin-round"/><path d="m15.01 5.75-4.5 4.5M15.01 10.25l-4.5-4.5"/></svg>,
"bug": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 5h6a1 1 0 0 1 1 1v5.5A3.5 3.5 0 0 1 8.5 15h-1A3.5 3.5 0 0 1 4 11.5V6a1 1 0 0 1 1-1ZM5 5a3 3 0 0 1 6 0" className="stroke-linejoin-round"/><path d="M12 9h3M1 9h3M12 12h2l1 2M4 12H2l-1 2M12 6h2l1-2M4 6H2L1 4"/></svg>,
"calendar": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M2 2h12v12H2z" className="stroke-linejoin-round"/><path d="M4.99 8H5v.01h-.01zM7.99 8H8v.01h-.01zM10.99 8H11v.01h-.01zM4.99 5H5v.01h-.01zM7.99 5H8v.01h-.01zM10.99 5H11v.01h-.01zM4.99 11H5v.01h-.01zM7.99 11H8v.01h-.01z"/></svg>,
"call": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 2c2.8 0 5 2.2 5 5M7.9 13c1.3 1.3 3.5 1.3 4.8 0l1-1c.4-.4.4-1 0-1.4l-1.5-1.5c-.3-.3-1-.2-1.3.1l-.1.1c-.5.5-1.4.5-2 0L6.6 7.2c-.5-.5-.5-1.4 0-2l.1-.1c.3-.3.4-1 .1-1.3L5.3 2.3c-.3-.4-1-.4-1.3 0l-1 1C1.7 4.6 1.7 6.7 3 8.1L7.9 13z"/></svg>,
"caret-down-filled": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4 5h8l-4 6-4-6z" className="filled stroke-linejoin-round"/></svg>,
"caret-down": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4 5h8l-4 6-4-6z" className="stroke-linejoin-round"/></svg>,
"caret-left-filled": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M11 4v8L5 8l6-4z" className="filled stroke-linejoin-round"/></svg>,
"caret-right-filled": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 4v8l6-4-6-4z" className="filled stroke-linejoin-round"/></svg>,
"caret-up-filled": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4 11h8L8 5l-4 6z" className="filled stroke-linejoin-round"/></svg>,
"caret-up": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4 11h8L8 5l-4 6z" className="stroke-linejoin-round"/></svg>,
"check": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m14.254 4.254-8.25 8.25L6 12.5l-.004.004-4.25-4.25.008-.008L6 12.493l8.246-8.247.008.008Z"/></svg>,
"close": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m2 2 12 12M14 2 2 14"/></svg>,
"contact": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M2 12.286h5.143L8.857 14l1.714-1.714H14V2H2v10.286z" className="stroke-linejoin-round stroke-linecap-round"/><path d="M4.99 7H5v.01h-.01zM7.99 7H8v.01h-.01zM10.99 7H11v.01h-.01z" className="stroke-linecap-round"/></svg>,
"copy": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M2 5h9v9H2z" className="stroke-linejoin-round"/><path d="M5 5V2h9v9h-3" className="stroke-linejoin-round"/></svg>,
"delete-marker": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 7V1h6l5 5v9H9" className="stroke-linejoin-round"/><path d="M8 1v6h6M1 15l6-6M7 15 1 9" className="stroke-linejoin-round"/></svg>,
"download": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M11 2h3v12H2V2h3" className="stroke-linejoin-round"/><path d="m4 6 4 4 4-4M8 1v9"/></svg>,
"drag-indicator": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="5.5" cy="2.5" r=".5" className="filled"/><circle cx="5.5" cy="13.5" r=".5" className="filled"/><circle cx="5.5" cy="8" r=".5" className="filled"/><circle cx="10.5" cy="2.5" r=".5" className="filled"/><circle cx="10.5" cy="13.5" r=".5" className="filled"/><circle cx="10.5" cy="8" r=".5" className="filled"/></svg>,
"edit": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8.2 13.9H14v.1H8.1l.1-.1Zm2.37-9.17.7.7-5.3 5.3-.7-.7 5.3-5.3Zm2.71-2.7a.1.1 0 0 1 .14 0l.57.57a.1.1 0 0 1 0 .14l-.58.58-.71-.71.58-.58ZM3.15 12.15l.7.71-.24.24a1 1 0 0 1-.43.26l-.74.2.21-.73a1 1 0 0 1 .26-.44l.24-.24Z" className="filled"/></svg>,
"ellipsis": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="2.5" r=".5" className="filled"/><circle cx="8" cy="8" r=".5" className="filled"/><circle cx="8" cy="13.5" r=".5" className="filled"/></svg>,
"envelope": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M1 2h14v12H1z" className="stroke-linejoin-round"/><path d="m1 4 7 6 7-6" className="stroke-linejoin-round"/></svg>,
"expand": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 2h5v5M7 2H2v5M7 14H2V9M9 14h5V9M2 2l12 12M14 2 2 14"/></svg>,
"external": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M10 2h4v4" className="stroke-linecap-square"/><path d="m6 10 8-8"/><path d="M14 9.048V14H2V2h5" className="stroke-linejoin-round"/></svg>,
"file-open": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8 1v4a1 1 0 0 0 1 1h4"/><path d="M3 15h10V5L9 1H3v14z" className="stroke-linejoin-round"/><path d="m3 8 7 7"/></svg>,
"file": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8 1v5h5" className="stroke-linejoin-round"/><path d="M3 15V1h6l4 4v10H3z" className="stroke-linejoin-round"/></svg>,
"filter": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M14.986 2.016A.01.01 0 0 0 14.98 2H1.02a.01.01 0 0 0-.007.016l4.984 5.981A.01.01 0 0 1 6 8.004v6.98a.01.01 0 0 0 .014.009l3.98-1.99a.01.01 0 0 0 .006-.01v-4.99c0-.002 0-.004.002-.006l4.984-5.98Z"/></svg>,
"flag": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m2.71 9.18 1.11-.39C5.2 8.3 6.74 8.44 8 9.18c1.27.74 2.8.89 4.18.39l1.11-.39v-6l-1.11.39c-1.38.49-2.92.35-4.18-.39-1.27-.74-2.8-.89-4.18-.39l-1.11.39v11.83" className="stroke-linejoin-round"/></svg>,
"folder-open": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M2 7V2h7l1 2h5v9a1 1 0 0 1-1 1H3L1 7h10l2.006 7" className="stroke-linejoin-round"/></svg>,
"folder": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M14 14H2V2h5.143L9 5h5v9z" className="stroke-linejoin-round"/></svg>,
"gen-ai": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7.981 1.047a.02.02 0 0 1 .038 0l1.96 4.973 4.974 1.961a.02.02 0 0 1 0 .038L9.98 9.979l-1.961 4.974a.02.02 0 0 1-.038 0L6.021 9.98 1.046 8.019a.02.02 0 0 1 0-.038l4.973-1.96 1.961-4.974Z"/><path d="m2.5 2.65.045-.095.095-.045-.095-.045L2.5 2.37l-.045.095-.095.045.095.045.045.095Z" className="filled"/></svg>,
"group-active": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM9 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3Z" className="stroke-linejoin-round"/><path d="M6 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM1 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3Z" className="filled stroke-linejoin-round"/></svg>,
"group": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM9 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3ZM6 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM1 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3Z" className="stroke-linejoin-round"/></svg>,
"heart-filled": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M13.92 3.061a3.73 3.73 0 0 0-5.21 0L8 3.76l-.71-.699a3.729 3.729 0 0 0-5.21 0 3.58 3.58 0 0 0 0 5.123l5.21 5.124.003-.004L8 14l.707-.696.004.004 5.21-5.124a3.58 3.58 0 0 0-.001-5.123Z" className="filled"/></svg>,
"heart": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M13.92 3.061a3.729 3.729 0 0 0-5.21 0L8 3.76l-.71-.699a3.729 3.729 0 0 0-5.21 0 3.58 3.58 0 0 0 0 5.123l5.21 5.124.003-.004L8 14l.707-.696.004.004 5.21-5.124a3.58 3.58 0 0 0 0-5.123Z"/></svg>,
"insert-row": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 6V3H1v9h5M16 11H8M12 15V7" className="stroke-linejoin-round"/></svg>,
"key": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 10a5.023 5.023 0 0 1 0 1 3.996 3.996 0 1 1-3-3.874L13 1h2v5h-2v2h-2l.016 1.983Z" className="stroke-linejoin-round"/><path d="M4.99 11H5v.01h-.01z"/></svg>,
"keyboard": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 2H1v12h14V2Z" className="stroke-linejoin-round"/><path d="M6 5H4M9 5H7M12 5h-2M6 8H4M12 11H4M9 8H7M12 8h-2"/></svg>,
"lock-private": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M2 7h12v7H2z" className="stroke-linejoin-round"/><path d="M4 7V5a4 4 0 0 1 8 0v2"/></svg>,
"menu": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M15 8H1M15 3H1M15 13H1"/></svg>,
"microphone-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13.76 1.34 2.11 12.98M4.75 10.8A5.002 5.002 0 0 0 13 7V6M8 15v-3M3 15h10" className="stroke-linejoin-round"/><path d="M8 1c-1.1 0-2 .9-2 2v1.09l2.88-2.88C8.61 1.08 8.32 1 8 1ZM6.64 8.46A2 2 0 0 0 8 9c1.1 0 2-.9 2-2V5.09L6.64 8.45v.01Z" className="filled"/><path d="M3.37 8.53C3.024 8.184 3 7.537 3 7V6" className="stroke-linejoin-round"/></svg>,
"microphone": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><rect x="6" y="1" width="4" height="8" rx="2" className="stroke-linejoin-round"/><path d="M3 6v1a5 5 0 0 0 10 0V6M8 12v3M3 15h10" className="stroke-linejoin-round"/></svg>,
"multiscreen": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 5H1v7h10V5Z" className="stroke-linejoin-round"/><path d="M11 8.229h4v-7H5V5M1 15h10M6 15v-3" className="stroke-linejoin-round"/></svg>,
"notification": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8 2.167c-3.5 0-3.5 4.666-3.5 4.666L1 11.5h14l-3.5-4.667s0-4.666-3.5-4.666ZM5.667 11.5v1.167a2.333 2.333 0 0 0 4.666 0V11.5" className="stroke-linejoin-round"/><path d="M8 1v1.167" className="stroke-linecap-round"/></svg>,
"redo": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M12 5H6.38c-4.5 0-4.5 8 0 8H13" className="stroke-linejoin-round"/><path d="m10 8 3-3-3-3" className="stroke-linejoin-round"/></svg>,
"refresh": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M10 5h5V0"/><path d="M15 8a6.957 6.957 0 0 1-7 7 6.957 6.957 0 0 1-7-7 6.957 6.957 0 0 1 7-7 6.87 6.87 0 0 1 6.3 4"/></svg>,
"remove": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m3 3 1 11h8l1-11H3ZM1 3h14M10 2H6v1h4V2Z" className="stroke-linejoin-round"/></svg>,
"resize-area": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m9.5 14.5 5-5M4 14.5 14.5 4"/></svg>,
"script": <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m9.53 4.397-3.06 7.4M4.5 11.597 1 8.097l3.5-3.5M11.5 11.597l3.5-3.5-3.5-3.5" className="stroke-linejoin-round"/></svg>,
"search": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="7" cy="7" r="5"/><path d="m15 15-4.5-4.5"/></svg>,
"security": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 14.83s-6-1.925-6-6.74V3.265c3.31 0 5.99-1.916 5.99-1.916s2.7 1.916 6.01 1.916V8.09c0 4.815-6 6.74-6 6.74Z" className="stroke-linejoin-round"/><path d="M2 8.09V3.265c3.31 0 5.99-1.916 5.99-1.916M13.99 8.09V3.265C10.68 3.265 8 1.35 8 1.35"/><path d="M8 4v5M8 10v2" className="stroke-linejoin-round"/></svg>,
"send": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m1 1.001 14 7.004L1 15l2.693-6.994L1 1.001ZM3.693 8.005h10.271" className="stroke-linejoin-round"/></svg>,
"settings": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M13.33 5.792a1.942 1.942 0 0 1 .287-1.97 6.984 6.984 0 0 0-1.44-1.439 1.943 1.943 0 0 1-3.159-1.308 6.965 6.965 0 0 0-2.037 0 1.943 1.943 0 0 1-3.158 1.308 6.962 6.962 0 0 0-1.44 1.44 1.943 1.943 0 0 1-1.308 3.158 6.972 6.972 0 0 0 0 2.037 1.943 1.943 0 0 1 1.308 3.159 6.952 6.952 0 0 0 1.44 1.44 1.942 1.942 0 0 1 3.159 1.308 6.962 6.962 0 0 0 2.036 0 1.942 1.942 0 0 1 3.159-1.308 6.974 6.974 0 0 0 1.44-1.44 1.943 1.943 0 0 1 1.308-3.159 6.974 6.974 0 0 0 0-2.037 1.942 1.942 0 0 1-1.596-1.189Z"/><circle cx="8" cy="8" r="2"/></svg>,
"share": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="11" cy="4" r="2"/><circle cx="4" cy="8" r="2"/><circle cx="11" cy="12" r="2"/><path d="M9.2 4.9 5.8 7.1M9.2 11.1 5.8 8.9"/></svg>,
"shrink": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M10 1v5h5M10 6l5-5M6 15v-5H1M6 10l-5 5"/></svg>,
"star-filled": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m8 1 2.16 4.61 4.84.74-3.5 3.58.83 5.07L8 12.61 3.67 15l.83-5.07L1 6.35l4.84-.74L8 1z" className="filled stroke-linejoin-round stroke-linecap-round"/></svg>,
"star-half": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m8 1 2.16 4.61 4.84.74-3.5 3.58.83 5.07L8 12.61 3.67 15l.83-5.07L1 6.35l4.84-.74L8 1z" className="stroke-linejoin-round"/><path d="M8 12.61 3.67 15l.83-5.07L1 6.35l4.84-.74L8 1v11.61z" className="filled"/></svg>,
"star": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m8 1 2.16 4.61 4.84.74-3.5 3.58.83 5.07L8 12.61 3.67 15l.83-5.07L1 6.35l4.84-.74L8 1z" className="stroke-linejoin-round stroke-linecap-round"/></svg>,
"status-in-progress": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7" className="stroke-linejoin-round"/><path d="M4.99 7.995H5v.01h-.01zM7.99 7.995H8v.01h-.01zM10.99 7.995H11v.01h-.01z" className="stroke-linecap-round"/></svg>,
"status-info": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7" className="stroke-linejoin-round"/><path d="M8 11V8H6"/><path d="M10 11H6" className="stroke-linejoin-round"/><path d="M7.99 5H8v.01h-.01z"/></svg>,
"status-negative": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7" className="stroke-linejoin-round"/><path d="m10.828 5.172-5.656 5.656M10.828 10.828 5.172 5.172"/></svg>,
"status-pending": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7" className="stroke-linejoin-round"/><path d="M8 5v4H5" className="stroke-linecap-square"/></svg>,
"status-positive": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7" className="stroke-linejoin-round"/><path d="m5 8 2 2 3.521-3.521" className="stroke-linecap-square"/></svg>,
"status-stopped": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7" className="stroke-linejoin-round"/><path d="M11 8H5" className="stroke-linecap-square"/></svg>,
"status-warning": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m8 1 7 14H1L8 1z" className="stroke-linejoin-round"/><path d="M7.99 12H8v.01h-.01zM8 6v4"/></svg>,
"subtract-minus": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 8h14"/></svg>,
"suggestions": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 14h4" className="stroke-linejoin-round"/><path d="M8 11h1c0-1.53 3-2.46 3-5.24.02-1.36-.61-2.4-1.57-3.09a4.208 4.208 0 0 0-4.86 0C4.61 3.36 3.98 4.4 4 5.76 4 8.54 7 9.47 7 11h1Z"/></svg>,
"thumbs-down-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 9.58c0 .27.06.55.19.79l1.64 3.28c.1.21.32.34.55.34.34 0 .62-.28.62-.62v-3.33h3.44a2 2 0 0 0 1.94-2.48l-1.01-4.05a2 2 0 0 0-1.94-1.52H6v7.59Z" className="filled stroke-linejoin-round"/><path d="M6 2H2v7h4" className="stroke-linejoin-round"/></svg>,
"thumbs-down": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 9.58c0 .27.06.55.19.79l1.64 3.28c.1.21.32.34.55.34.34 0 .62-.28.62-.62v-3.33h3.44a2 2 0 0 0 1.94-2.48l-1.01-4.05a2 2 0 0 0-1.94-1.52H6v7.59ZM6 2H2v7h4" className="stroke-linejoin-round"/></svg>,
"thumbs-up-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 6.42c0-.27.06-.55.19-.79l1.64-3.28c.1-.21.32-.34.55-.34.34 0 .62.28.62.62v3.33h3.44a2 2 0 0 1 1.94 2.48l-1.01 4.05a2 2 0 0 1-1.94 1.52H6V6.42Z" className="filled stroke-linejoin-round"/><path d="M6 14H2V7h4" className="stroke-linejoin-round"/></svg>,
"thumbs-up": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 6.42c0-.27.06-.55.19-.79l1.64-3.28c.1-.21.32-.34.55-.34.34 0 .62.28.62.62v3.33h3.44a2 2 0 0 1 1.94 2.48l-1.01 4.05a2 2 0 0 1-1.94 1.52H6V6.42ZM6 14H2V7h4" className="stroke-linejoin-round"/></svg>,
"ticket": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 4.275a2 2 0 0 1-2-2H3v12h3a2 2 0 1 1 4 0h3v-12h-3a2 2 0 0 1-2 2Z" className="stroke-linejoin-round"/></svg>,
"treeview-collapse": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 8h6M2 2h12v12H2z" className="stroke-linejoin-round"/></svg>,
"treeview-expand": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 8h6M8 11V5M2 2h12v12H2z" className="stroke-linejoin-round"/></svg>,
"undo": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4 13h5.625c4.5 0 4.5-8 0-8H3" className="stroke-linejoin-round"/><path d="M6 2 3 5l3 3" className="stroke-linejoin-round"/></svg>,
"unlocked": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M2 7h12v7H2z" className="stroke-linejoin-round"/><path d="M11.874 4A4 4 0 0 0 4 5v2"/></svg>,
"upload-download": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 13.701v-10M8 6.702l3-3 3 3M4.98 2.662v10.04M7.98 9.662l-3 3-3-3"/></svg>,
"upload": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 14H2V2h12v12h-3" className="stroke-linejoin-round"/><path d="M12 10 8 6l-4 4M8 6v9"/></svg>,
"user-profile-active": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4.61 4.39C4.61 2.52 6.13 1 8 1s3.39 1.52 3.39 3.39S9.87 7.78 8 7.78 4.61 6.26 4.61 4.39M2.05 14.08h11.91c0-2.25-1.17-4.23-2.9-5.3A5.77 5.77 0 0 0 8 7.91c-3.29 0-5.95 2.76-5.95 6.17z" className="filled stroke-linejoin-round"/></svg>,
"user-profile": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M4.61 4.39C4.61 2.52 6.13 1 8 1s3.39 1.52 3.39 3.39S9.87 7.78 8 7.78 4.61 6.26 4.61 4.39M2.05 14.08h11.91c0-2.25-1.17-4.23-2.9-5.3A5.77 5.77 0 0 0 8 7.91c-3.29 0-5.95 2.76-5.95 6.17z" className="stroke-linejoin-round"/></svg>,
"video-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 15h10M8 15v-3M8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10ZM6 5l4 4M10 5 6 9" className="stroke-linejoin-round"/></svg>,
"video-on": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 15h10M8 15v-3M8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10Z" className="stroke-linejoin-round"/><path d="M8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" className="stroke-linejoin-round"/></svg>,
"video-unavailable": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 15h10M8 15v-3M8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10ZM5.5 7h5" className="stroke-linejoin-round"/></svg>,
"view-full": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 5h6v6H5z" className="filled"/><path d="M2 2h12v12H2z" className="stroke-linejoin-round"/></svg>,
"view-horizontal": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M5 9h6v2H5z" className="filled"/><path d="M2 2h12v12H2z" className="stroke-linejoin-round"/></svg>,
"view-vertical": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M11 5v6H9V5z" className="filled"/><path d="M2 2h12v12H2z" className="stroke-linejoin-round"/></svg>,
"zoom-in": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="6.885" cy="6.885" r="5.385"/><path d="m14.5 14.5-3.846-3.846M7 4v6M10 7H4"/></svg>,
"zoom-out": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="6.885" cy="6.885" r="5.385"/><path d="m14.5 14.5-3.846-3.846M10 7H4"/></svg>,
"zoom-to-fit": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 11v3h-4M2 11v3h4M2 5V2h4M14 5V2h-4M10 6H6v4h4V6Z"/></svg>
  };
  export default icons;