import moment from 'moment'

export function format (data, format, classes) {
  let pos = 0
  const acc = []
  const done = () => `${data.slice(0, pos)}${acc.join('')}`

  // Step 1: Take correctly-formatted prefix
  const matches = () => classes.hasOwnProperty(format[pos])
    ? classes[format[pos]](data[pos])
    : format[pos] === data[pos]
  const maxLen = Math.min(format.length, data.length)
  while (pos < maxLen && matches()) { pos++ }
  if (pos === maxLen) { return done() }

  // Step 2: Consume the rest according to the format
  let posF = pos
  let posD = pos
  const findNext = (f) => {
    while (posD < data.length) {
      const c = data[posD]
      posD++
      if (f(c)) { return c }
    }
    return null
  }
  while (posF < format.length && posD < data.length) {
    if (classes.hasOwnProperty(format[posF])) {
      const c = findNext(classes[format[posF]])
      if (c == null) { return done() }
      acc.push(c)
    } else {
      acc.push(format[posF])
    }
    posF++
  }
  return done()
}

export function trimStringsAndRemoveEmpty (obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      const trimmed = obj[key].trim()
      if (trimmed === '') {
        obj[key] = null
      } else {
        obj[key] = trimmed
      }
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map(item => {
        if (typeof item === 'string') {
          return item.trim()
        } else if (typeof item === 'object' && item !== null) {
          return trimStringsAndRemoveEmpty(item)
        }
        return item
      }).filter(item => item !== '') // Filter out empty strings
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = trimStringsAndRemoveEmpty(obj[key])
    }
  })
  return obj
}

export function formatCurrency (inputString) {
  if (inputString == null) {
    return null
  }
  const number = parseFloat(inputString)
  return `$${number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export function formatDate (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY')
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function isNullOrWhitespace (value) {
  return !value || !value.trim()
}

export function toTitleCase (str) {
  return str.replace(/([A-Z])/g, ' $1').replace(str[0], str[0].toUpperCase())
}
