import React, {} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { useConfirm } from '../hooks/useConfirm'
import { RadAlert } from '../common/RadAlert'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function Children ({ item, formValues, setFormValues, suffixOptions, raceEthnicityOptions, tribeOptions, translate, canExclude }) {
  const { data: schoolDistrictOptions } = useGet('/api/option/school-district')

  const gradeOptions = [
    { value: 'Head Start', label: translate('Head Start') },
    { value: 'PreK', label: translate('PreK') },
    { value: 'Kindergarten', label: translate('Kindergarten') },
    { value: '1st', label: translate('1st') },
    { value: '2nd', label: translate('2nd') },
    { value: '3rd', label: translate('3rd') },
    { value: '4th', label: translate('4th') },
    { value: '5th', label: translate('5th') },
    { value: '6th', label: translate('6th') },
    { value: '7th', label: translate('7th') },
    { value: '8th', label: translate('8th') },
    { value: '9th', label: translate('9th') },
    { value: '10th', label: translate('10th') },
    { value: '11th', label: translate('11th') },
    { value: '12th', label: translate('12th') }
  ]

  if (schoolDistrictOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader
            variant='h2'
          >
            {translate('Child Information')}
          </RadHeader>
        }
      >
        <RadSpaceBetween size='s'>
          <RadBox color='text-body-secondary'>
            <RadSpaceBetween size='xxs'>
              <span>{translate('Use the following section to add each child in your household attending school, Head Start or preK-12th grade, who would like to participate in the Summer EBT for Children program. You can add as many school aged children as needed by selecting the "Add child" button found below this section.')}</span>
              <span>{translate('For this application, an 18 or 19 year old who graduated from high school this school year (2023-2024) is considered a child and should be entered in the Child Information section.')}</span>
              <span>{translate('Head Start: Children enrolled in Head Start must be 3 years or older to qualify for this program. Children who turned three after September 1, 2023 are not eligible to receive benefits.')}</span>
            </RadSpaceBetween>
          </RadBox>
          <RadSpaceBetween size='xxs'>
            {formValues.children.map((item) =>
              <Editor
                key={`child-${item.id ?? item.uuid}`}
                item={item}
                formValues={formValues}
                setFormValues={setFormValues}
                gradeOptions={gradeOptions}
                suffixOptions={suffixOptions}
                raceEthnicityOptions={raceEthnicityOptions}
                schoolDistrictOptions={schoolDistrictOptions}
                tribeOptions={tribeOptions}
                translate={translate}
                canExclude={canExclude}
              />
            )}
          </RadSpaceBetween>
          <br />
          <RadButton
            onClick={() => {
              const children = formValues.children
              children.push({ uuid: uuidv4(), excluded: false })
              setFormValues({ ...formValues, children })
            }}
          >
            {translate('Add child')}
          </RadButton>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function Editor ({ item, formValues, setFormValues, gradeOptions, suffixOptions, raceEthnicityOptions, schoolDistrictOptions, tribeOptions, translate, canExclude }) {
  const confirmExclude = useConfirm(
    'Confirm exclude child from benefit recipients',
    'Please confirm this child should be excluded from benefit recipients. The child will still be included in the count of household members.',
    'Confirm',
    () => {
      const children = formValues.children
      item.excluded = true
      setFormValues({ ...formValues, children })
    })

  const selectedSchool = item.schoolDistrictId != null ? schoolDistrictOptions.find(x => x.value === item.schoolDistrictId.toString()) : null
  const schoolDataShare = selectedSchool != null && selectedSchool.dataSharing === true
  const schoolApplicationClosed = selectedSchool != null && selectedSchool.tribalArea === 'Muscogee' && selectedSchool.ebtProvider === 'Cherokee'

  return (
    <RadSpaceBetween size='l'>
      {item !== formValues.children[0] && <RadDivider />}
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <RadFormField label={translate('First Name')} field={`child.${item.id ?? item.uuid}.firstName`} required>
          <RadInput
            placeholder={translate('Enter first name')}
            ariaRequired
            value={item.firstName}
            onChange={({ detail }) => {
              const children = formValues.children
              item.firstName = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Middle Name')} field={`child.${item.id ?? item.uuid}.middleName`}>
          <RadInput
            placeholder={translate('Enter middle name')}
            ariaRequired
            value={item.middleName}
            onChange={({ detail }) => {
              const children = formValues.children
              item.middleName = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Last Name')} field={`child.${item.id ?? item.uuid}.lastName`} required>
          <RadInput
            placeholder={translate('Enter last name')}
            ariaRequired
            value={item.lastName}
            onChange={({ detail }) => {
              const children = formValues.children
              item.lastName = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Suffix')} field={`child.${item.id ?? item.uuid}.suffix`}>
          <RadSelect
            filteringType='auto'
            selectedOption={suffixOptions.find(x => x.value === item.suffix)}
            onChange={({ detail }) => {
              const children = formValues.children
              item.suffix = detail.selectedOption.value
              setFormValues({ ...formValues, children })
            }}
            options={suffixOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a suffix')}
            empty={translate('No matches found')}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <RadFormField label={translate('Race / Ethnicity')} field={`child.${item.id ?? item.uuid}.raceEthnicity`}>
          <RadSelect
            filteringType='auto'
            selectedOption={raceEthnicityOptions.find(x => x.value === item.raceEthnicity)}
            onChange={({ detail }) => {
              const children = formValues.children
              item.raceEthnicity = detail.selectedOption.value
              setFormValues({ ...formValues, children })
            }}
            options={raceEthnicityOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a race / ethnicity')}
            empty={translate('No matches found')}
          />
        </RadFormField>
        {item.raceEthnicity === 'Native American' &&
          <RadFormField label={translate('Tribe')} field={`child.${item.id ?? item.uuid}.raceEthnicity`}>
            <RadSelect
              filteringType='auto'
              selectedOption={item.otherTribe ? { value: 'Other', label: translate('Other') } : tribeOptions.find(x => x.value === item.tribe)}
              onChange={({ detail }) => {
                const children = formValues.children
                if (detail.selectedOption.value === 'Other') {
                  item.otherTribe = true
                  item.tribe = null
                } else {
                  item.otherTribe = false
                  item.tribe = detail.selectedOption.value
                }
                setFormValues({ ...formValues, children })
              }}
              options={tribeOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel={translate('Selected')}
              placeholder={translate('Choose a tribe')}
              empty={translate('No matches found')}
            />
          </RadFormField>}
        {item.otherTribe &&
          <RadFormField label={translate('If other tribe please specify')} field={`child.${item.id ?? item.uuid}.raceEthnicity`}>
            <RadInput
              placeholder={translate('Enter tribe')}
              value={item.tribe}
              onChange={({ detail }) => {
                const children = formValues.children
                item.tribe = detail.value
                setFormValues({ ...formValues, children })
              }}
            />
          </RadFormField>}
        <RadFormField label={translate('Birth Date')} field={`child.${item.id ?? item.uuid}.birthDate`} required>
          <RadInput
            ariaRequired
            type='date'
            placeholder={translate('Enter birth date')}
            value={item.birthDate}
            onChange={({ detail }) => {
              const children = formValues.children
              item.birthDate = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } }
        ]}
      >
        <RadFormField label={translate('Grade')} field={`child.${item.id ?? item.uuid}.grade`} required>
          <RadSelect
            filteringType='auto'
            selectedOption={gradeOptions.find(x => x.value === item.grade)}
            onChange={({ detail }) => {
              const children = formValues.children
              item.grade = detail.selectedOption.value
              setFormValues({ ...formValues, children })
            }}
            options={gradeOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a grade')}
            empty={translate('No matches found')}
          />
        </RadFormField>
        <RadFormField label={translate('School District')} field={`child.${item.id ?? item.uuid}.schoolDistrictId`}>
          <RadSelect
            filteringType='auto'
            disabled={item.homeSchool === true || item.epicCharterSchool === true || item.otherSchool === true}
            selectedOption={schoolDistrictOptions.find(x => x.value === `${item.schoolDistrictId}`)}
            onChange={({ detail }) => {
              const children = formValues.children
              if (detail.selectedOption.value === '') {
                item.schoolDistrictId = null
              } else {
                item.schoolDistrictId = parseInt(detail.selectedOption.value)
              }
              item.homeSchool = false
              item.epicCharterSchool = false
              item.otherSchool = false
              setFormValues({ ...formValues, children })
            }}
            options={schoolDistrictOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a school district')}
            empty={translate('No matches found')}
          />
        </RadFormField>
        <RadFormField label={translate('Home School?')} field={`child.${item.id ?? item.uuid}.homeSchool`} required>
          <RadRadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.homeSchool = detail.value
              if (detail.value === true) {
                item.epicCharterSchool = false
                item.schoolDistrictId = null
                item.otherSchool = false
              }
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.homeSchool}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </RadFormField>
        <RadFormField label={translate('Charter School?')} field={`child.${item.id ?? item.uuid}.epicCharterSchool`} required>
          <RadRadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.epicCharterSchool = detail.value
              if (detail.value === true) {
                item.homeSchool = false
                item.schoolDistrictId = null
                item.otherSchool = false
              }
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.epicCharterSchool}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </RadFormField>
        <RadFormField label={translate('Other School?')} field={`child.${item.id ?? item.uuid}.otherSchool`} required>
          <RadRadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.otherSchool = detail.value
              if (detail.value === true) {
                item.homeSchool = false
                item.epicCharterSchool = false
                item.schoolDistrictId = null
              }
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.otherSchool}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </RadFormField>
      </RadGrid>

      {(item.schoolDistrictId != null && (schoolApplicationClosed || schoolDataShare)) &&
        <RadAlert statusIconAriaLabel='Warning' type='warning'>{
          `${schoolApplicationClosed
            ? ' ' + translate('Applications associated with this school are no longer being accepted for benefits.')
            : ''}
          ${schoolDataShare
            ? ' ' + translate('This school is participating in data sharing for this program and your student may be automatically enrolled if they receive free or reduced priced meals.')
            : ''}`
        }
        </RadAlert>}

      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <RadFormField label={translate('Foster Child?')} field={`child.${item.id ?? item.uuid}.fosterChild`} required>
          <RadRadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.fosterChild = detail.value
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.fosterChild}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </RadFormField>
        <RadFormField label={translate('Homeless / Migrant / Runaway?')} field={`child.${item.id ?? item.uuid}.homelessMigrantRunaway`} required>
          <RadRadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.homelessMigrantRunaway = detail.value
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.homelessMigrantRunaway}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </RadFormField>
      </RadGrid>
      {canExclude &&
        <RadFormField label={translate('Exclude child from benefit recipients?')} field={`child.${item.id ?? item.uuid}.excluded`} required>
          <RadRadioGroup
            onChange={({ detail }) => {
              if (detail.value === true) {
                confirmExclude()
              } else {
                const children = formValues.children
                item.excluded = detail.value
                setFormValues({ ...formValues, children })
              }
            }}
            ariaRequired
            value={item.excluded}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </RadFormField>}
      {formValues.children.length > 1 &&
        <RadBox>
          <RadButton
            wrapText={false}
            onClick={() => {
              const children = formValues.children.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, children })
            }}
          >
            {translate('Remove child')}
          </RadButton>
        </RadBox>}
    </RadSpaceBetween>
  )
}
