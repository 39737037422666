
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18582_1ke47_141",
  "child": "awsui_child_18582_1ke47_145",
  "horizontal": "awsui_horizontal_18582_1ke47_156",
  "horizontal-xxxs": "awsui_horizontal-xxxs_18582_1ke47_160",
  "horizontal-xxs": "awsui_horizontal-xxs_18582_1ke47_163",
  "horizontal-xs": "awsui_horizontal-xs_18582_1ke47_166",
  "horizontal-s": "awsui_horizontal-s_18582_1ke47_169",
  "horizontal-m": "awsui_horizontal-m_18582_1ke47_172",
  "horizontal-l": "awsui_horizontal-l_18582_1ke47_175",
  "horizontal-xl": "awsui_horizontal-xl_18582_1ke47_178",
  "horizontal-xxl": "awsui_horizontal-xxl_18582_1ke47_181",
  "vertical": "awsui_vertical_18582_1ke47_188",
  "vertical-xxxs": "awsui_vertical-xxxs_18582_1ke47_191",
  "vertical-xxs": "awsui_vertical-xxs_18582_1ke47_194",
  "vertical-xs": "awsui_vertical-xs_18582_1ke47_197",
  "vertical-s": "awsui_vertical-s_18582_1ke47_200",
  "vertical-m": "awsui_vertical-m_18582_1ke47_203",
  "vertical-l": "awsui_vertical-l_18582_1ke47_206",
  "vertical-xl": "awsui_vertical-xl_18582_1ke47_209",
  "vertical-xxl": "awsui_vertical-xxl_18582_1ke47_212",
  "align-center": "awsui_align-center_18582_1ke47_216",
  "align-start": "awsui_align-start_18582_1ke47_220",
  "align-end": "awsui_align-end_18582_1ke47_224"
};
  