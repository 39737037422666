import React from 'react'
import { RadFlashBar } from './RadFlashBar'

export function SiteBanner ({ message }) {
  const items = [
    {
      type: 'info',
      content: message,
      id: 'message_0'
    }
  ]

  return (
    <div className='site-banner'>
      <RadFlashBar items={items} />
    </div>
  )
}
