import { useContext } from 'react'
import { AppContext } from './../App'

export function useConfirm (title, message, actionLabel, onSuccess) {
  const { setConfirmModal } = useContext(AppContext)

  async function confirm () {
    setConfirmModal({ visible: true, title, message, actionLabel, onSuccess })
  }

  return confirm
}
