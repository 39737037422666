
    import './styles.scoped.css';
    export default {
  "item-element": "awsui_item-element_93a1u_j3xsa_141",
  "disabled": "awsui_disabled_93a1u_j3xsa_153",
  "show-divider": "awsui_show-divider_93a1u_j3xsa_160",
  "highlighted": "awsui_highlighted_93a1u_j3xsa_163",
  "is-focused": "awsui_is-focused_93a1u_j3xsa_178",
  "menu-item": "awsui_menu-item_93a1u_j3xsa_183",
  "has-category-header": "awsui_has-category-header_93a1u_j3xsa_198",
  "item-tooltip-wrapper": "awsui_item-tooltip-wrapper_93a1u_j3xsa_198",
  "has-checkmark": "awsui_has-checkmark_93a1u_j3xsa_198",
  "icon": "awsui_icon_93a1u_j3xsa_202",
  "checkmark": "awsui_checkmark_93a1u_j3xsa_206",
  "external-icon": "awsui_external-icon_93a1u_j3xsa_213"
};
  