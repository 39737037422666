
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_15r73_149",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_15r73_198",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_15r73_213",
  "navigation": "awsui_navigation_7nfqu_15r73_228",
  "tools": "awsui_tools_7nfqu_15r73_229",
  "split-panel-side": "awsui_split-panel-side_7nfqu_15r73_253",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_15r73_259",
  "panel-hidden": "awsui_panel-hidden_7nfqu_15r73_272",
  "toolbar-container": "awsui_toolbar-container_7nfqu_15r73_276",
  "notifications-container": "awsui_notifications-container_7nfqu_15r73_282",
  "main-landmark": "awsui_main-landmark_7nfqu_15r73_289",
  "main": "awsui_main_7nfqu_15r73_289",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_15r73_298",
  "content-header": "awsui_content-header_7nfqu_15r73_308",
  "content": "awsui_content_7nfqu_15r73_308",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_15r73_318"
};
  