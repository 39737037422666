
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_lm6vo_lnp81_150",
  "resize-side": "awsui_resize-side_lm6vo_lnp81_156",
  "resize-bottom": "awsui_resize-bottom_lm6vo_lnp81_159",
  "with-motion": "awsui_with-motion_lm6vo_lnp81_164",
  "root": "awsui_root_lm6vo_lnp81_179",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_lnp81_186",
  "layout": "awsui_layout_lm6vo_lnp81_191",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_lnp81_197",
  "layout-main": "awsui_layout-main_lm6vo_lnp81_201",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_lnp81_207",
  "unfocusable": "awsui_unfocusable_lm6vo_lnp81_212",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_lnp81_216",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_lnp81_221",
  "content-wrapper": "awsui_content-wrapper_lm6vo_lnp81_225",
  "content-overlapped": "awsui_content-overlapped_lm6vo_lnp81_229",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_lnp81_233"
};
  