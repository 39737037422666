
    import './styles.scoped.css';
    export default {
  "breadcrumb-group": "awsui_breadcrumb-group_d19fg_hvrk1_141",
  "item": "awsui_item_d19fg_hvrk1_179",
  "breadcrumb-group-list": "awsui_breadcrumb-group-list_d19fg_hvrk1_214",
  "ellipsis": "awsui_ellipsis_d19fg_hvrk1_226",
  "icon": "awsui_icon_d19fg_hvrk1_236",
  "mobile": "awsui_mobile_d19fg_hvrk1_241",
  "mobile-short": "awsui_mobile-short_d19fg_hvrk1_254"
};
  