import React from 'react'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function HouseholdEditor ({ household, formValues, setFormValues, translate }) {
  const incomeFrequencyOptions = [
    { value: 'Weekly', label: translate('Weekly') },
    { value: 'Biweekly', label: translate('Biweekly') },
    { value: 'Twice a Month', label: translate('Twice a Month') },
    { value: 'Monthly', label: translate('Monthly') },
    { value: 'Annually', label: translate('Annually') }
  ]

  const languageOptions = [
    { value: 'English', label: translate('English') },
    { value: 'Spanish', label: translate('Spanish') },
    { value: 'Other', label: translate('Other') }
  ]

  return (
    <RadSpaceBetween size='l'>
      <RadFormField label={translate('Primary Language Spoken')} field={'household.primaryLanguageSpoken' + (household.otherLanguageSpoken === true ? '-disable' : '')} required>
        <RadSelect
          filteringType='auto'
          selectedOption={household.otherLanguageSpoken ? { value: 'Other', label: translate('Other') } : languageOptions.find(x => x.value === household.primaryLanguageSpoken)}
          onChange={({ detail }) => {
            if (detail.selectedOption.value === 'Other') {
              household.otherLanguageSpoken = true
              household.primaryLanguageSpoken = null
            } else {
              household.otherLanguageSpoken = false
              household.primaryLanguageSpoken = detail.selectedOption.value
            }
            setFormValues({ ...formValues, household })
          }}
          options={languageOptions}
          enteredTextLabel={value => value}
          selectedAriaLabel={translate('Selected')}
          placeholder={translate('Choose a language')}
          empty={translate('No matches found')}
        />
      </RadFormField>
      {household.otherLanguageSpoken &&
        <RadFormField label={translate('If other language please specify')} field='household.primaryLanguageSpoken' required>
          <RadInput
            placeholder={translate('Enter language')}
            value={household.primaryLanguageSpoken}
            onChange={({ detail }) => {
              household.primaryLanguageSpoken = detail.value
              setFormValues({ ...formValues, household })
            }}
          />
        </RadFormField>}
      <div>
        <RadFormField
          label={translate('Which of the following programs is any member of your household currently participating in?')}
          description={translate('SNAP (also known as food stamps), TANF (Temporary Assistance for Needy Families), FDPIR (commodities or Food Distribution Program on Indian Reservations), Medicaid (SoonerCare).')}
          required
        />
        <RadFormField field='snap' label='SNAP' screenReader>
          <RadCheckbox
            checked={household.snap}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.snap = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            SNAP
          </RadCheckbox>
        </RadFormField>
        <RadFormField field='tanf' label='TANF' screenReader>
          <RadCheckbox
            checked={household.tanf}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.tanf = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            TANF
          </RadCheckbox>
        </RadFormField>
        <RadFormField field='fdpir' label='FDPIR' screenReader>
          <RadCheckbox
            checked={household.fdpir}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.fdpir = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            FDPIR
          </RadCheckbox>
        </RadFormField>
        <RadFormField field='medicaid' label='Medicaid' screenReader>
          <RadCheckbox
            checked={household.medicaid}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.medicaid = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            Medicaid
          </RadCheckbox>
        </RadFormField>
        <RadFormField field='none' label='None' screenReader>
          <RadCheckbox
            checked={household.none}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.snap = false
                household.tanf = false
                household.fdpir = false
                household.medicaid = false
              }
              household.none = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            {translate('None')}
          </RadCheckbox>
        </RadFormField>
        <RadFormField field='household.directCertification' label='Used for displaying validation errors' screenReader />
      </div>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 6 } },
          { colspan: { default: 6 } }
        ]}
      >
        <RadFormField label={translate('Household Income Frequency')} field='household.incomeFrequency' required>
          <RadSelect
            filteringType='auto'
            placeholder={translate('Choose a frequency')}
            options={incomeFrequencyOptions}
            selectedOption={incomeFrequencyOptions.find(x => x.value === household.incomeFrequency)}
            onChange={({ detail }) => {
              household.incomeFrequency = detail.selectedOption.value
              setFormValues({ ...formValues, household })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Household Income Amount')} field='household.incomeAmount' required>
          <RadInput
            ariaRequired
            placeholder={translate('Enter income amount')}
            value={household.incomeAmount}
            onChange={({ detail }) => {
              household.incomeAmount = detail.value
              setFormValues({ ...formValues, household })
            }}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 6, s: 2 } }
        ]}
      >
        <RadFormField label={translate('PIN')} field='household.pin'>
          <RadInput
            placeholder={translate('Enter pin')}
            type='number'
            value={household.pin}
            onChange={({ detail }) => {
              household.pin = detail.value
              setFormValues({ ...formValues, household })
            }}
          />
        </RadFormField>
      </RadGrid>
    </RadSpaceBetween>
  )
}
