import React, { useContext } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components/side-navigation'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = React.useState(rootHref)

  return (
    <SideNavigation
      activeHref={activeHref}
      // header={{ href: '/', text: 'SEBT', logo: { alt: 'logo', src: '/logo.png' } }}
      onFollow={(event) => {
        if (isMobile) setNavigationOpen(false)
        setError(null) // Chipi - I might not need this
        setActiveHref(event.detail.href)
      }}
      items={[
        { type: 'link', text: 'Home', href: '/admin' },
        // { type: 'link', text: 'Apply', href: '/admin/apply' },
        // { type: 'link', text: 'MIO', href: '/admin/mio' },
        // { type: 'divider' },
        { type: 'link', text: 'Applications', href: '/admin/application' },
        { type: 'link', text: 'Child Verifications', href: '/admin/child-verification' },
        { type: 'link', text: 'Audit', href: '/admin/audit' },
        { type: 'link', text: 'Passive Enrollments', href: '/admin/passive-enrollment' },
        { type: 'link', text: 'Users', href: '/admin/user' },
        { type: 'link', text: 'Roles', href: '/admin/role' }
      ]}
    />
  )
}
