import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function SecondaryContact ({ secondaryContact, formValues, setFormValues, suffixOptions, phoneTypeOptions, translate }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          {translate('Secondary Contact Information')}
        </RadHeader>
    }
    >
      <RadSpaceBetween size='l'>
        <RadBox color='text-body-secondary'>
          {translate('Please add a secondary contact to whom we may reach out about your benefits if we are unable to reach the primary contact. This might help speed up your receipt of benefits.')}
        </RadBox>
        {formValues.secondaryContact == null &&
          <RadButton
            onClick={() => setFormValues({ ...formValues, secondaryContact: { phone: {} } })}
          >
            {translate('Add secondary contact')}
          </RadButton>}
        {formValues.secondaryContact != null &&
          <RadSpaceBetween size='l'>
            <Editor
              secondaryContact={formValues.secondaryContact}
              formValues={formValues}
              setFormValues={setFormValues}
              suffixOptions={suffixOptions}
              phoneTypeOptions={phoneTypeOptions}
              translate={translate}
            />
            <RadButton
              onClick={() => setFormValues({ ...formValues, secondaryContact: null })}
            >
              {translate('Remove secondary contact')}
            </RadButton>
          </RadSpaceBetween>}
      </RadSpaceBetween>
    </RadContainer>
  )
}

export function Editor ({ secondaryContact, formValues, setFormValues, suffixOptions, phoneTypeOptions, translate }) {
  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <RadFormField label={translate('First Name')} field='secondaryContact.firstName' required>
          <RadInput
            ariaRequired
            placeholder={translate('Enter first name')}
            value={secondaryContact?.firstName}
            onChange={({ detail }) => {
              secondaryContact.firstName = detail.value
              setFormValues({ ...formValues, secondaryContact })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Middle Name')} field='secondaryContact.middleName'>
          <RadInput
            placeholder={translate('Enter middle name')}
            value={secondaryContact?.middleName}
            onChange={({ detail }) => {
              secondaryContact.middleName = detail.value
              setFormValues({ ...formValues, secondaryContact })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Last Name')} field='secondaryContact.lastName' required>
          <RadInput
            ariaRequired
            placeholder={translate('Enter last name')}
            value={secondaryContact?.lastName}
            onChange={({ detail }) => {
              secondaryContact.lastName = detail.value
              setFormValues({ ...formValues, secondaryContact })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Suffix')} field='secondaryContact.suffix'>
          <RadSelect
            filteringType='auto'
            selectedOption={suffixOptions.find(x => x.value === secondaryContact?.suffix)}
            onChange={({ detail }) => {
              secondaryContact.suffix = detail.selectedOption.value
              setFormValues({ ...formValues, secondaryContact })
            }}
            options={suffixOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a suffix')}
            empty={translate('No matches found')}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 4 } },
          { colspan: { default: 4 } },
          { colspan: { default: 4 } }
        ]}
      >
        <RadFormField label={translate('Email')} field='secondaryContact.email'>
          <RadInput
            ariaRequired
            placeholder={translate('Enter email')}
            type='email'
            value={secondaryContact?.email}
            onChange={({ detail }) => {
              secondaryContact.email = detail.value
              setFormValues({ ...formValues, secondaryContact })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Phone Number')} field='secondaryContact.phone.number'>
          <RadInput
            ariaRequired
            placeholder='999-999-9999 #999999999'
            type='tel'
            value={secondaryContact?.phone?.number}
            onChange={({ detail }) => {
              secondaryContact.phone.number = detail.value
              setFormValues({ ...formValues, secondaryContact })
            }}
          />
        </RadFormField>
        <RadFormField label={translate('Phone Type')} field='secondaryContact.phone.type'>
          <RadSelect
            filteringType='auto'
            placeholder={translate('Choose a phone type')}
            options={phoneTypeOptions}
            selectedOption={phoneTypeOptions.find(x => x.value === secondaryContact?.phone?.type)}
            onChange={({ detail }) => {
              secondaryContact.phone.type = detail.selectedOption.value
              setFormValues({ ...formValues, secondaryContact })
            }}
          />
        </RadFormField>
      </RadGrid>
    </RadSpaceBetween>
  )
}
