import React, { useContext } from 'react'
import parse from 'html-react-parser'
import Form from '@cloudscape-design/components-themed/form'
import { AppContext } from './../App'
import { translations } from '../translations'

// https://cloudscape.design/components/form
export function RadForm ({ ...rest }) {
  const { error, language } = useContext(AppContext)
  const errorText = error?.message ?? error?.code

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.warn('No translation found for', text)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  return (
    <Form
      errorText={errorText != null ? parse(translate(errorText)) : null}
      {...rest}
    />
  )
}
