
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1mabk_hq2iy_141",
  "radio": "awsui_radio_1mabk_hq2iy_177",
  "radio--has-description": "awsui_radio--has-description_1mabk_hq2iy_185",
  "radio-control": "awsui_radio-control_1mabk_hq2iy_189",
  "outline": "awsui_outline_1mabk_hq2iy_197",
  "styled-circle-border": "awsui_styled-circle-border_1mabk_hq2iy_219",
  "styled-circle-disabled": "awsui_styled-circle-disabled_1mabk_hq2iy_223",
  "styled-circle-readonly": "awsui_styled-circle-readonly_1mabk_hq2iy_223",
  "styled-circle-fill": "awsui_styled-circle-fill_1mabk_hq2iy_228",
  "styled-circle-checked": "awsui_styled-circle-checked_1mabk_hq2iy_234"
};
  