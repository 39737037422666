import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
// import { utils, writeFile } from 'xlsx'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadIcon } from '../common/RadIcon'
import { RadLink } from '../common/RadLink'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { formatDate } from '../common/utilities'

export function ChildVerificationList () {
  const [searchParams, setSearchParams] = useSearchParams()
  const [originalValues, setOriginalValues] = useState()
  const [formValues, setFormValues] = useState()
  const [reloadCounter, setReloadCounter] = useState(0)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const [schoolDistrictId, setSchoolDistrictId] = useState(searchParams.get('schoolDistrictId') ?? '')
  const [grade, setGrade] = useState(searchParams.get('grade') ?? '')
  const { data: applications, count } = useGet(`/api/child-verification?search=${encodeURIComponent(searchText)}&status=${searchStatus}&schoolDistrictId=${schoolDistrictId}&grade=${grade}&reload=${reloadCounter}`)
  const { data: schoolDistrictOptions } = useGet('/api/option/school-district?includeAll=true')
  const update = usePut('/api/child-verification', formValues, (resp) => { setReloadCounter(reloadCounter + 1) })

  useEffect(() => {
    if (applications != null) {
      const values = applications.reduce((acc, x) => {
        for (const child of x.children) {
          acc[child.id.toString()] = child.verified
        }
        return acc
      }, {})
      setOriginalValues(values)
      setFormValues(values)
    }
  }, [applications])

  const statusOptions = [
    { label: 'Needs Verification', value: '' },
    { label: 'Verified: Yes', value: 'true' },
    { label: 'Verified: No', value: 'false' }
  ]

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  const gradeOptions = [
    { value: '', label: 'All Grades' },
    { value: 'Head Start', label: 'Head Start' },
    { value: 'PreK', label: 'PreK' },
    { value: 'Kindergarten', label: 'Kindergarten' },
    { value: '1st', label: '1st' },
    { value: '2nd', label: '2nd' },
    { value: '3rd', label: '3rd' },
    { value: '4th', label: '4th' },
    { value: '5th', label: '5th' },
    { value: '6th', label: '6th' },
    { value: '7th', label: '7th' },
    { value: '8th', label: '8th' },
    { value: '9th', label: '9th' },
    { value: '10th', label: '10th' },
    { value: '11th', label: '11th' },
    { value: '12th', label: '12th' }
  ]

  if (
    applications != null && schoolDistrictOptions != null && formValues != null
  ) {
    const dirty = Object.keys(formValues).some(key => formValues[key] !== originalValues[key])
    const invalidApplications = applications
      .filter(x => x.children.some(y => formValues[y.id.toString()] == null) && x.children.some(y => formValues[y.id.toString()] != null))

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={`(${count > 20 ? 'First ' : ''}${applications.length})`}
            description='You can search by child name.'
          >
            Child Verifications
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadTable
              columnDefinitions={[
                {
                  id: 'applicationId',
                  header: 'Application ID',
                  cell: item => <RadLink href={`/admin/application/${item.id}`}>{highlightMatch(item.id.toString())}</RadLink>
                },
                {
                  id: 'fullName',
                  header: 'Child',
                  cell: item => item.children.map(x => <div key={'full_name-' + x.id}>{highlightMatch(x.fullName)}</div>)
                },
                {
                  id: 'birthDate',
                  header: 'Birth Date',
                  cell: item => item.children.map(x => <div key={'birth_date-' + x.id}>{formatDate(x.birthDate)}</div>)
                },
                {
                  id: 'schoolDistrict',
                  header: 'School District',
                  cell: item => item.children.map(x => <div key={'school_district-' + x.id}>{x.schoolDistrict ?? '-'}</div>)
                },
                {
                  id: 'grade',
                  header: 'Grade',
                  cell: item => item.children.map(x => <div key={'grade-' + x.id}>{x.grade}</div>)
                },
                {
                  id: 'verified',
                  header: 'Verified',
                  cell: item =>
                    <>
                      {item.children.map(x =>
                        <div key={'verified-' + x.id}>
                          <RadSpaceBetween direction='horizontal' size='l'>
                            <RadCheckbox
                              checked={formValues[x.id.toString()] === true}
                              onChange={({ detail }) => {
                                if (detail.checked) {
                                  setFormValues({ ...formValues, [x.id.toString()]: true })
                                } else {
                                  setFormValues({ ...formValues, [x.id.toString()]: null })
                                }
                              }}
                            >
                              Yes
                            </RadCheckbox>
                            <RadCheckbox
                              checked={formValues[x.id.toString()] === false}
                              onChange={({ detail }) => {
                                if (detail.checked) {
                                  setFormValues({ ...formValues, [x.id.toString()]: false })
                                } else {
                                  setFormValues({ ...formValues, [x.id.toString()]: null })
                                }
                              }}
                            >
                              No
                            </RadCheckbox>
                          </RadSpaceBetween>
                        </div>)}
                      {invalidApplications.find(x => x.id === item.id) != null &&
                        <RadBox color='text-status-error' padding={{ top: 'xxs' }}>
                          <RadIcon name='status-warning' />
                          &nbsp;Make a selection for all
                          <br />
                          children on this application
                        </RadBox>}
                    </>
                }
              ]}
              items={applications}
              variant='full-page'
              filter={
                <RadGrid
                  gridDefinition={[
                    { colspan: { default: 12, xs: 5 } },
                    { colspan: { default: 12, xs: 3 } },
                    { colspan: { default: 12, xs: 2 } },
                    { colspan: { default: 12, xs: 2 } }
                  ]}
                >
                  <RadTextFilter
                    stretch
                    filteringPlaceholder='Search'
                    filteringAriaLabel='Search participants'
                    filteringText={filteringText}
                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                    onDelayedChange={({ detail }) => {
                      setSearchText(detail.filteringText)
                      if (detail.filteringText) {
                        searchParams.set('search', detail.filteringText)
                      } else {
                        searchParams.delete('search')
                      }
                      setSearchParams(searchParams)
                    }}
                  />
                  <RadSelect
                    filteringType='auto'
                    selectedOption={schoolDistrictOptions.find(x => x.value === schoolDistrictId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value === '') {
                        setSchoolDistrictId('')
                        searchParams.delete('schoolDistrictId')
                      } else {
                        setSchoolDistrictId(detail.selectedOption.value)
                        searchParams.set('schoolDistrictId', detail.selectedOption.value)
                      }
                      setSearchParams(searchParams)
                    }}
                    options={schoolDistrictOptions}
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Choose a school district'
                    empty='No matches found'
                  />
                  <RadSelect
                    filteringType='auto'
                    selectedOption={gradeOptions.find(x => x.value === grade)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value === '') {
                        setGrade('')
                        searchParams.delete('grade')
                      } else {
                        setGrade(detail.selectedOption.value)
                        searchParams.set('grade', detail.selectedOption.value)
                      }
                      setSearchParams(searchParams)
                    }}
                    options={gradeOptions}
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Choose a grade'
                    empty='No matches found'
                  />
                  <RadSelect
                    onChange={({ detail }) => {
                      setSearchStatus(detail.selectedOption.value)
                      if (detail.selectedOption.value === '') {
                        searchParams.delete('status')
                      } else {
                        searchParams.set('status', detail.selectedOption.value)
                      }
                      setSearchParams(searchParams)
                    }}
                    options={statusOptions}
                    selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                    placeholder='Choose status'
                  />
                </RadGrid>
              }
              empty={
                <RadBox textAlign='center' color='inherit'>
                  No matches found.
                </RadBox>
              }
            />
            <RadBox float='right'>
              <RadButton
                onClick={() => update()}
                variant='primary'
                disabled={!dirty || invalidApplications.length > 0}
              >
                Save Changes
              </RadButton>
            </RadBox>
          </RadSpaceBetween>
        }
      />
    )
  }
}
