import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { translations } from '../translations'

// Leaving this for now in case it's needed later

export function PassiveEnrollmentEdit () {
  const { language } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { passiveEnrollmentId } = useParams()
  const { data: passiveEnrollment } = useGet(passiveEnrollmentId ? `/api/passive-enrollment/${passiveEnrollmentId}` : '', true)
  const { data: userInfo } = useGet('/api/user/current')
  const [formValues, setFormValues] = useState()
  const update = usePut(`/api/application/${passiveEnrollmentId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/admin/application/${passiveEnrollmentId}`) })
  const confirmUpdate = useConfirm('Confirm Save Changes', 'Submitting this edit will remove the application\'s current status. Are you sure you want to proceed?', 'Save Changes', () => { update() })

  useEffect(() => {
    if (passiveEnrollment != null) {
      setFormValues(passiveEnrollment)
    }
  }, [passiveEnrollment])

  useEffect(() => {
    if (formValues != null) {
      setFormValues({ ...formValues, language })
    }
  }, [language])

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.warn('No translation found for', text)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  if (
    formValues != null &&
    userInfo != null
  ) {
    // const hasStatuses = passiveEnrollment.statuses.length > 0
    // const currentStatus = hasStatuses && passiveEnrollment.statuses[0].status
    // const canDecline = currentStatus === 'escalated' && (userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Exclude Child from Benefit Recipients')) != null)

    // const saveChangesButton = (
    //   <RadButton
    //     onClick={() => {
    //       if (formValues.statuses.length > 0) {
    //         confirmUpdate()
    //       } else {
    //         update()
    //       }
    //     }}
    //     formAction='submit'
    //     variant='primary'
    //     disabled={
    //       (currentStatus === 'accepted') ||
    //       isNullOrWhitespace(formValues.signature2) ||
    //       formValues.agreeEsignature2 !== true ||
    //       formValues.concentToSignOnBehalfOf !== true
    //     }
    //   >
    //     Save Changes
    //   </RadButton>
    // )

    return (
      <RadAppLayout
        name={passiveEnrollment.id}
        contentHeader={
          <RadHeader variant='h1'>
            {translate('Application')} # {passiveEnrollment.id}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={() => navigate(searchParams.get('redirectURL') ?? `/admin/passive-enrollment/${passiveEnrollmentId}`)}>Cancel</RadButton>
                  <RadButton
                    onClick={() => {
                      if (formValues.statuses.length > 0) {
                        confirmUpdate()
                      } else {
                        update()
                      }
                    }}
                    formAction='submit'
                    variant='primary'
                  >
                    Save Changes
                  </RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                    >
                      {translate('Admin Controls')}
                    </RadHeader>
}
                >
                  <RadSpaceBetween size='s'>
                    <RadFormField field='bypassMailingAddress'>
                      <RadCheckbox
                        checked={formValues.bypassMailingAddress ?? false}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, bypassMailingAddress: detail.checked })
                        }}
                      >
                        Mailing address outside of Oklahoma, application should proceed.
                      </RadCheckbox>
                    </RadFormField>
                    <RadFormField field='bypassPotentialDuplicate'>
                      <RadCheckbox
                        checked={formValues.bypassPotentialDuplicate ?? false}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, bypassPotentialDuplicate: detail.checked })
                        }}
                      >
                        Application is not a potential duplicate.
                      </RadCheckbox>
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
