import React, { useContext } from 'react'
import { AppContext } from '../App'
import { RadModal } from './RadModal'
import { RadSpaceBetween } from './RadSpaceBetween'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'

// This utilizes other RAD components
// See each for links to their documentation
export function RadConfirmationModal () {
  const { confirmModal, setConfirmModal } = useContext(AppContext)

  function dismiss () {
    setConfirmModal({ visible: false })
  }

  function doIt () {
    confirmModal.onSuccess()
    dismiss()
  }

  return (
    <RadModal
      onDismiss={dismiss}
      visible={confirmModal.visible}
      closeAriaLabel='Close modal'
      footer={
        <RadBox float='right'>
          <RadSpaceBetween direction='horizontal' size='xs'>
            <RadButton variant='link' onClick={dismiss}>Cancel</RadButton>
            <RadButton variant='primary' onClick={doIt}>{confirmModal.actionLabel}</RadButton>
          </RadSpaceBetween>
        </RadBox>
      }
      header={confirmModal.title ?? 'Title'}
    >
      {confirmModal.message ?? 'Message'}
    </RadModal>
  )
}
