import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx/xlsx.mjs'
import { toTitleCase } from 'titlecase'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadBox } from '../common/RadBox'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { formatDate, formatDateTime } from '../common/utilities'

export function PassiveEnrollmentList () {
  const pageLength = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [exportingEnrollments, setExportingEnrollments] = useState(false)
  const [exportingContacts, setExportingContacts] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const [provider, setProvider] = useState(searchParams.get('provider') ?? '')
  const { data: passiveEnrollments, count } = useGet(
    '/api/passive-enrollment' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&provider=${provider}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const { data: exportedPassiveEnrollments } = useGet(
    exportingEnrollments
      ? '/api/passive-enrollment' +
      `?search=${encodeURIComponent(searchText)}` +
      `&status=${searchStatus}` +
      `&provider=${provider}` +
      '&limit=50000'
      : null
  )
  const { data: exportedContacts } = useGet(
    exportingContacts
      ? '/api/passive-enrollment' +
      `?search=${encodeURIComponent(searchText)}` +
      '&status=accepted' +
      `&provider=${provider}` +
      '&limit=50000'
      : null
  )

  const ebtProviderOptions = [
    { label: 'All providers', value: '' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Chickasaw', value: 'Chickasaw' }
  ]

  const statusOptions = [
    { label: 'All statuses', value: '' },
    { label: 'Submitted', value: 'submitted' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Declined', value: 'declined' },
    { label: 'Escalated', value: 'escalated' },
    { label: 'Rejected', value: 'rejected' }
  ]

  const getColor = (item) => {
    switch (item?.status) {
      case 'accepted':
        return 'text-status-success'
      case 'rejected':
        return 'text-status-error'
      case 'escalated':
        return 'text-status-warning'
      case 'declined':
        return 'text-status-warning'
      default:
        return null
    }
  }

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  useEffect(() => {
    if (exportingEnrollments && exportedPassiveEnrollments != null) {
      exportEnrollmentsToExcel()
      setExportingEnrollments(false)
    }
  }, [exportingEnrollments, exportedPassiveEnrollments])

  useEffect(() => {
    if (exportingContacts && exportedContacts != null) {
      exportContactsToExcel()
      setExportingContacts(false)
    }
  }, [exportingContacts, exportedContacts])

  function exportEnrollmentsToExcel () {
    const data = exportedPassiveEnrollments.map(x => ([
      '',
      formatDate(x.created_at),
      formatDate(x.status_date),
      '',
      '',
      '',
      '',
      '',
      x.status_reason ?? '-',
      [x.student_first_name, x.student_middle_name, x.student_last_name].filter(z => z != null).join(' ') + ' - ' + formatDate(x.student_birthdate),
      '',
      '',
      '',
      x.id.toString(),
      [x.guardian_first_name, x.guardian_middle_name, x.guardian_last_name].filter(z => z != null).join(' '),
      x.household_emails.join('\n') ?? '-',
      x.household_phones.join('\n') ?? '-',
      [x.mailing_address_line1, x.mailing_address_line2].filter(y => y != null).join('\n') + '\n' + [x.mailing_address_city, x.mailing_address_state, x.mailing_address_zip].join(' '),
      x.district_name,
      x.status != null ? x.status.charAt(0).toUpperCase() + x.status.slice(1) : 'Submitted'
    ]))
    const header = [
      'Status',
      'Date Submitted',
      'Status Date',
      'Date Entered',
      'HFO Staff',
      'Date Contacted',
      'Contact Method',
      'Date Accepted',
      'Reason',
      'Child(ren) Name and Birthdate',
      'Case Notes',
      'Disposition',
      'Action Taken',
      'Case Number',
      'Parent Name',
      'Parent Email',
      'Parent Phone',
      'Address',
      'School District',
      'Application Status'
    ]
    data.unshift(header)

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `sebt_passiveenrollments_${year}_${month}_${date}.xlsx`

    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications')
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  function exportContactsToExcel () {
    const data = exportedContacts.map(x => ([
      x.guardian_first_name ?? '',
      x.guardian_middle_name ?? '',
      x.guardian_last_name ?? '',
      [x.student_first_name, x.student_middle_name, x.student_last_name].filter(z => z != null).join(' '),
      formatDate(x.student_birthdate),
      x.id.toString(),
      x.district_name,
      x.mailing_address_line1 ?? '',
      x.mailing_address_line2 ?? '',
      x.mailing_address_city ?? '',
      x.mailing_address_state ?? '',
      x.mailing_address_zip ?? '',
      x.household_phones[0] ?? '',
      x.household_phones[1] ?? '',
      x.household_phones[2] ?? '',
      x.household_emails[0] ?? '',
      x.household_emails[1] ?? '',
      x.household_emails[2] ?? ''
    ]))
    const header = [
      'Parent First Name',
      'Parent Middle Name',
      'Parent Last Name',
      'Child Name',
      'Child DOB',
      'Case Number',
      'District',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Zip',
      'Parent Phone 1',
      'Parent Phone 2',
      'Parent Phone 3',
      'Parent Email 1',
      'Parent Email 2',
      'Parent Email 3'
    ]
    data.unshift(header)

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `sebt_accepted_passive_enrollment_contacts_${year}_${month}_${date}.xlsx`

    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts')
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  if (
    passiveEnrollments != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => setExportingEnrollments(true)}>Export Passive Enrollments</RadButton>
                <RadButton onClick={() => setExportingContacts(true)}>Export Accepted Passive Enrollment Contacts</RadButton>
              </RadSpaceBetween>
            }
            variant='h1'
            counter={'(' + Number(count).toLocaleString() + ')'}
            description={
              <RadSpaceBetween size='xxs'>
                <div>You can search by student testing ID, student name, student birthdate, school district, guardian name, household phone, and mailing address.</div>
                <div>Exports are limited to 50,000 records.</div>
              </RadSpaceBetween>
            }
          >
            Passive Enrollments
          </RadHeader>
        }
        content={
          <RadTable
            columnDefinitions={[
              {
                id: 'id',
                header: '#',
                cell: item => <RadLink href={`/admin/passive-enrollment/${item.id}`}>{highlightMatch(item.id.toString())}</RadLink>
              },
              {
                id: 'studentTestingNumber',
                header: 'Student Testing ID',
                cell: item => <div>{highlightMatch(item.student_testing_number ?? '-')}</div>
              },
              {
                id: 'student',
                header: 'Student',
                cell: item => <div>{highlightMatch(item.student_first_name + ' ' + (item.student_middle_name ?? '') + ' ' + item.student_last_name)} - {highlightMatch(formatDate(item.student_birthdate))}</div>
              },
              {
                id: 'districtName',
                header: 'School District',
                cell: item => <div>{highlightMatch(item.district_name)}</div>
              },
              {
                id: 'status',
                header: 'Status',
                cell: item => <RadBox color={getColor(item)}>{toTitleCase(item.status ?? 'submitted')}</RadBox>
              },
              {
                id: 'createdAt',
                header: 'Created',
                cell: item => formatDateTime(item.created_at)
              }
              // {
              //   id: 'actions',
              //   cell: item => <RadButton disabled={item.status === 'accepted'} variant='inline-icon' iconName='edit' href={`/admin/application/${item.id}/edit?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`} />

              // }
            ]}
            items={passiveEnrollments}
            variant='full-page'
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search participants'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.delete('page')
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  onChange={({ detail }) => {
                    setSearchStatus(detail.selectedOption.value)
                    setCurrentPageIndex(1)
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('status')
                    } else {
                      searchParams.set('status', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={statusOptions}
                  selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                  placeholder='Choose status'
                />
                <RadSelect
                  onChange={({ detail }) => {
                    setProvider(detail.selectedOption.value)
                    setCurrentPageIndex(1)
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('provider')
                    } else {
                      searchParams.set('provider', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={ebtProviderOptions}
                  selectedOption={ebtProviderOptions.find((x) => x.value === provider) ?? ebtProviderOptions[0]}
                  placeholder='Choose EBT provider'
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
