import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadFormField } from '../common/RadFormField'
import { RadButton } from '../common/RadButton'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadTextarea } from '../common/RadTextarea'

export function Settings () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const { data: settings } = useGet('/api/mio/settings')

  useEffect(() => {
    setFormValues(settings)
  }, [settings])

  const update = usePut('/api/mio/settings/update', formValues, (resp) => { navigate('/mio') })

  if (formValues != null) {
    return (
      <RadAppLayout
        name={formValues.name}
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'MIO', href: '/mio' },
              { text: 'Settings' }
            ]}
          />
        }
        contentHeader={
          <RadHeader variant='h1'>
            MIO Settings
          </RadHeader>
        }
        content={
          <RadForm
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton variant='link' onClick={() => { navigate('/mio') }}>Cancel</RadButton>
                <RadButton formAction='submit' variant='primary' onClick={() => update()}>Save Changes</RadButton>
              </RadSpaceBetween>
            }
          >
            <RadContainer>
              <RadSpaceBetween size='l'>
                <RadFormField label='Welcome Message *' field='welcomeMessage' stretch>
                  <RadTextarea
                    placeholder='Enter welcome message'
                    ariaRequired
                    value={formValues.welcomeMessage}
                    onChange={({ detail }) => setFormValues({ ...formValues, welcomeMessage: detail.value })}
                  />
                </RadFormField>
                <RadGrid
                  gridDefinition={[
                    { colspan: { default: 6 } },
                    { colspan: { default: 6 } }
                  ]}
                >
                  <RadFormField label='Model *' field='model'>
                    <RadInput
                      placeholder='Enter model'
                      ariaRequired
                      value={formValues.model}
                      onChange={({ detail }) => setFormValues({ ...formValues, model: detail.value })}
                    />
                  </RadFormField>
                  <RadFormField label='Temperature *' field='temperature'>
                    <RadInput
                      placeholder='Enter temperature'
                      ariaRequired
                      value={formValues.temperature}
                      onChange={({ detail }) => setFormValues({ ...formValues, temperature: detail.value })}
                    />
                  </RadFormField>
                </RadGrid>
                <RadFormField label='System Message *' field='systemMessage' stretch>
                  <RadTextarea
                    placeholder='Enter system message'
                    ariaRequired
                    rows={20}
                    value={formValues.systemMessage}
                    onChange={({ detail }) => setFormValues({ ...formValues, systemMessage: detail.value })}
                  />
                </RadFormField>
              </RadSpaceBetween>
            </RadContainer>
          </RadForm>
        }
      />
    )
  }
}
