import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './../App'
import { useGet } from '../hooks/useGet'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatCurrency, formatDate, formatDateTime } from '../common/utilities'
import { translations } from '../translations'
import { Footer } from './Footer'

export function View () {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const { language, setLanguage } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false)
  const { data: application } = useGet(`/api/application/uuid/${uuid}`, true)

  useEffect(() => {
    setLanguage(application?.language)
  }, [application])

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.warn('No translation found for', text)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  const links = (
    <>
      <li><Link to='/' role='button'>{translate('HOME')}</Link></li>
      <li>{translate('APPLICATION')}</li>
      <li><Link to='/cherokee-nation-benefits' role='button'>{translate('CHEROKEE NATION BENEFITS')}</Link></li>
      <li><Link to='/chickasaw-nation-benefits' role='button'>{translate('CHICKASAW NATION BENEFITS')}</Link></li>
      <li>
        {/* <label id='language-menu-label' for='language-menu' className='screen-reader-only'>Language:</label> */}
        <select
          onChange={(event) => {
            setLanguage(event.target.value)
            setIsOpen(false)
          }}
          id='language-menu'
          ariay-labelledby='language-menu-label'
          name='language-menu'
          value={language}
        >
          <option value='English'>{translate('ENGLISH')}</option>
          <option value='Spanish'>{translate('SPANISH')}</option>
        </select>
      </li>
    </>
  )

  if (application != null) {
    return (
      <>
        <section className='main_div main_menu_wrap menu_section_wrap'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 mnu_top text-center'>
                <ul className='web_nav'>
                  {links}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className='responsive_header white-background' id='back_top_menu'>
          <div className='container-fluid px-lg-5'>
            <div className='row pding align-items-center'>
              <div className='col-8 ps-5' />
              <div className='mobile_icon_wrap col-4' onClick={() => setIsOpen(!isOpen)}>
                <div className='mobile_icon'>
                  <i className='fas fa-bars' aria-hidden='true' />
                </div>
              </div>
            </div>
          </div>
          <ul id={isOpen ? 'open_nav' : null} className='top_menu responsive_header_nav pt-5 web_nav_wrap'>
            {links}
          </ul>
        </div>
        <div className='menu_holder' />

        <section className='main_div eligibility_blue' style={{ margin: '0 0 50px 0' }}>
          <div className='container-fluid px-lg-5'>
            <h1 className='normal-case white' style={{ fontSize: '28px' }}>
              {translate('Application')} # {application.id}
              {application.statuses.length === 0 && <RadButton className='edit' onClick={() => navigate(`/edit/${uuid}`)}>{translate('Edit')}</RadButton>}
              {application.statuses.length > 0 && application.statuses[0].status !== 'accepted' && application.statuses[0].status !== 'rejected' && <div className='edit'>{translate('This application is being processed and can no longer be edited.')}</div>}
              {application.statuses.length > 0 && application.statuses[0].status === 'accepted' && <div>{translate('Your application for Summer EBT benefits has been accepted. An EBT card will be mailed to your mailing address.')}</div>}
              {application.statuses.length > 0 && application.statuses[0].status === 'rejected' && <div>{translate('Your application for Summer EBT benefits has been processed. Unfortunately, you are not eligible for benefits through the Muscogee Nation.')}</div>}
            </h1>
          </div>
        </section>

        <section>
          <div className='container-fluid px-lg-5'>
            <RadSpaceBetween size='l'>
              {/* Primary Contact Information */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('Primary Contact Information')}
                  </RadHeader>
              }
              >
                <RadColumnLayout columns={4} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Name')}
                    </RadBox>
                    <div>{`${application.primaryContact.firstName} ${application.primaryContact.middleName ? application.primaryContact.middleName + ' ' : ''} ${application.primaryContact.lastName} ${application.primaryContact.suffix ?? ''}`}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Race / Ethnicity')}
                    </RadBox>
                    <div>{translate(application.primaryContact.raceEthnicity ?? '-')}</div>
                  </div>
                  {application.primaryContact.tribe != null &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        {translate('Tribe')}
                      </RadBox>
                      <div>{application.primaryContact.tribe}</div>
                    </div>}
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Birth Date')}
                    </RadBox>
                    <div>{formatDate(application.primaryContact.birthDate)}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Mailing Address')}
                    </RadBox>
                    <div>{application.primaryContact.mailingAddress.line1}</div>
                    <div>{application.primaryContact.mailingAddress.line2}</div>
                    <div>{application.primaryContact.mailingAddress.city}, {application.primaryContact.mailingAddress.state} {application.primaryContact.mailingAddress.zip}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Physical Address')}
                    </RadBox>
                    <div>{application.primaryContact.physicalAddress.line1}</div>
                    <div>{application.primaryContact.physicalAddress.line2}</div>
                    <div>{application.primaryContact.physicalAddress.city}, {application.primaryContact.physicalAddress.state} {application.primaryContact.physicalAddress.zip}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Email')}
                    </RadBox>
                    <div>{application.primaryContact.email ? <RadLink href={`mailto:${application.primaryContact.email}`}>{application.primaryContact.email}</RadLink> : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Phone(s)')}
                    </RadBox>
                    <div>{application.primaryContact.phone1.number} - {application.primaryContact.phone1.type}</div>
                    {application.primaryContact.phone2 != null && <div>{application.primaryContact.phone2?.number} - {application.primaryContact.phone2?.type}</div>}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Consent to be Contacted')}
                    </RadBox>
                    <div>{application.primaryContact.consentToContact ? translate('Yes') : 'No'}</div>
                  </div>
                  {application.primaryContact.preferredContactMethod != null &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        {translate('Preferred Contact Method')}
                      </RadBox>
                      <div>{application.primaryContact.preferredContactMethod}</div>
                    </div>}
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Member of Household') + ' / ' + translate('Role')}
                    </RadBox>
                    <div>{application.primaryContact.householdMember ? `Yes - ${application.primaryContact.role}` : 'No'}</div>
                  </div>
                </RadColumnLayout>
              </RadContainer>

              {/* Secondary Contact Information */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('Secondary Contact Information')}
                  </RadHeader>
              }
              >
                {application.secondaryContact == null && <RadBox color='text-status-inactive'>No secondary contact information provided.</RadBox>}
                {application.secondaryContact != null &&
                  <RadColumnLayout columns={3} borders='vertical'>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        {translate('Name')}
                      </RadBox>
                      <div>{`${application.secondaryContact.firstName} ${application.secondaryContact.middleName ? application.secondaryContact.middleName + ' ' : ''} ${application.secondaryContact.lastName} ${application.secondaryContact.suffix ?? ''}`}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        {translate('Email')}
                      </RadBox>
                      <div>{application.secondaryContact.email ? <RadLink href={`mailto:${application.secondaryContact.email}`}>{application.secondaryContact.email}</RadLink> : '-'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        {translate('Phone')}
                      </RadBox>
                      <div>{application.secondaryContact.phone?.number} - {application.secondaryContact.phone?.type}</div>
                    </div>
                  </RadColumnLayout>}
              </RadContainer>

              {/* Household Information */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('Household Information')}
                  </RadHeader>
              }
              >
                <RadColumnLayout columns={4} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Primary Language Spoken')}
                    </RadBox>
                    <div>{application.household.primaryLanguageSpoken}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Program(s)')}
                    </RadBox>
                    <div>{application.household.snap && 'SNAP'}</div>
                    <div>{application.household.tanf && 'TANF'}</div>
                    <div>{application.household.fdpir && 'FDPIR'}</div>
                    <div>{application.household.medicaid && 'Medicaid'}</div>
                    <div>{!application.household.snap && !application.household.tanf && !application.household.fdpir && !application.household.medicaid && '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Income')}
                    </RadBox>
                    <div>{formatCurrency(application.household.incomeAmount)} / {application.household.incomeFrequency}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('PIN')}
                    </RadBox>
                    <div>{application.household.pin ?? '-'}</div>
                  </div>
                </RadColumnLayout>
              </RadContainer>

              {/* Child Information */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('Child Information')}
                  </RadHeader>
              }
              >
                {application.children.map((child, index) => (
                  <div key={'child-' + child.id}>
                    {index > 0 && <br />}
                    {index > 0 && <RadDivider />}
                    <RadColumnLayout columns={4} borders='vertical'>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Name')}
                        </RadBox>
                        <div>{`${child.firstName} ${child.middleName ? child.middleName + ' ' : ''} ${child.lastName} ${child.suffix ?? ''}`}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Race / Ethnicity')}
                        </RadBox>
                        <div>{translate(child.raceEthnicity ?? '-')}</div>
                      </div>
                      {child.tribe != null &&
                        <div>
                          <RadBox variant='awsui-key-label'>
                            {translate('Tribe')}
                          </RadBox>
                          <div>{child.tribe}</div>
                        </div>}
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Birth Date')}
                        </RadBox>
                        <div>{formatDate(child.birthDate)}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Grade')}
                        </RadBox>
                        <div>{child.grade}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('School')}
                        </RadBox>
                        {child.schoolDistrict != null && <div>{child.schoolDistrict.name} SCHOOL DISTRICT<br />{child.schoolDistrict.county != null ? `${child.schoolDistrict.county} COUNTY` : ''} </div>}
                        {child.homeSchool && <div>Home School</div>}
                        {child.epicCharterSchool && <div>Charter School</div>}
                        {child.otherSchool && <div>Other</div>}
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Foster Child?')}
                        </RadBox>
                        <div>{child.fosterChild ? translate('Yes') : 'No'}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Homeless / Migrant / Runaway?')}
                        </RadBox>
                        <div>{child.homelessMigrantRunaway ? translate('Yes') : 'No'}</div>
                      </div>
                    </RadColumnLayout>
                  </div>
                ))}
              </RadContainer>

              {/* Household Member Information */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('Household Member Information')}
                  </RadHeader>
              }
              >
                {application.householdMembers.length === 0 && <RadBox color='text-status-inactive'>No other household members provided.</RadBox>}
                {application.householdMembers.map((member, index) => (
                  <div key={'member-' + member.id}>
                    {index > 0 && <br />}
                    {index > 0 && <RadDivider />}
                    <RadColumnLayout columns={3} borders='vertical'>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Name')}
                        </RadBox>
                        <div>{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''} ${member.lastName} ${member.suffix ?? ''}`}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Race / Ethnicity')}
                        </RadBox>
                        <div>{translate(member.raceEthnicity ?? '-')}</div>
                      </div>
                      {member.tribe != null &&
                        <div>
                          <RadBox variant='awsui-key-label'>
                            {translate('Tribe')}
                          </RadBox>
                          <div>{member.tribe}</div>
                        </div>}
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Birth Date')}
                        </RadBox>
                        <div>{formatDate(member.birthDate)}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Role')}
                        </RadBox>
                        <div>{translate(member.role)}</div>
                      </div>
                    </RadColumnLayout>
                  </div>
                ))}
              </RadContainer>

              {/* E-Signature(s) */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('eSignature')}(s)
                  </RadHeader>
              }
              >
                <RadSpaceBetween size='l'>
                  <div className='signature-container'>
                    <div className='signature' style={{ lineHeight: '38px' }}>{application.signature ?? ''}</div>
                    <div className='date'>{formatDateTime(application.createdAt)}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
                    </RadBox>
                    <div>{application.agreeEsignature === true ? translate('Yes') : 'No'}</div>
                  </div>
                </RadSpaceBetween>
                {application.signature2 != null &&
                  <>
                    <RadDivider />
                    <RadSpaceBetween size='l'>
                      <div className='signature-container'>
                        <div className='signature' style={{ lineHeight: '38px' }}>{application.signature2 ?? ''}</div>
                        <div className='date'>{formatDateTime(application.updatedAt)}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
                        </RadBox>
                        <div>{application.agreeEsignature2 === true ? translate('Yes') : 'No'}</div>
                      </div>
                    </RadSpaceBetween>
                  </>}
              </RadContainer>

              <Footer translate={translate} />
            </RadSpaceBetween>
          </div>
        </section>
      </>
    )
  }
}
