
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_ln90f_145",
  "warning-icon-shake-wrapper": "awsui_warning-icon-shake-wrapper_14mhv_ln90f_146",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_ln90f_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_ln90f_176",
  "warning-icon-scale-wrapper": "awsui_warning-icon-scale-wrapper_14mhv_ln90f_177",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_ln90f_1",
  "warning": "awsui_warning_14mhv_ln90f_146",
  "error": "awsui_error_14mhv_ln90f_145",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_ln90f_1",
  "root": "awsui_root_14mhv_ln90f_228",
  "label": "awsui_label_14mhv_ln90f_264",
  "info": "awsui_info_14mhv_ln90f_278",
  "description": "awsui_description_14mhv_ln90f_284",
  "constraint": "awsui_constraint_14mhv_ln90f_285",
  "hints": "awsui_hints_14mhv_ln90f_292",
  "constraint-has-validation-text": "awsui_constraint-has-validation-text_14mhv_ln90f_293",
  "secondary-control": "awsui_secondary-control_14mhv_ln90f_297",
  "controls": "awsui_controls_14mhv_ln90f_301",
  "label-hidden": "awsui_label-hidden_14mhv_ln90f_301",
  "label-wrapper": "awsui_label-wrapper_14mhv_ln90f_304",
  "control": "awsui_control_14mhv_ln90f_301",
  "error__message": "awsui_error__message_14mhv_ln90f_331",
  "warning__message": "awsui_warning__message_14mhv_ln90f_332",
  "visually-hidden": "awsui_visually-hidden_14mhv_ln90f_336"
};
  