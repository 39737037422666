import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { AppContext } from './../App'
import { usePost } from '../hooks/usePost'
// import { RadAppLayout } from '../common/RadAppLayout'
import { SiteBanner } from '../common/SiteBanner'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { isNullOrWhitespace } from '../common/utilities'
import { OverrideEditor } from './OverrideEditor'
import { Children } from './Children'
import { HouseholdEditor } from './HouseholdEditor'
import { HouseholdMembers } from './HouseholdMembers'
import { PrimaryContactEditor } from './PrimaryContactEditor'
import { SecondaryContact } from './SecondaryContact'
import { Footer } from './Footer'
import { translations } from '../translations'
import './Application.scss'
import '../layout.css'

export function Application () {
  const { user, language, setLanguage } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    language: 'English',
    agreeEsignature: false,
    consentToApplyOnBehalfOf: null,
    iCertify: false,
    bypassMailingAddress: false,
    bypassPotentialDuplicate: false,
    primaryContact: {
      mailingAddress: {},
      physicalAddress: {},
      phone1: {},
      phone2: {},
      otherTribe: false,
      physicalAddressSameAsMailing: false
    },
    household: { snap: false, tanf: false, fdpir: false, medicaid: false, none: false, otherLanguageSpoken: false },
    householdMembers: [],
    children: [{ uuid: uuidv4(), otherTribe: false }]
  })
  const create = usePost('/api/application', formValues, (resp) => {
    if (user == null) {
      navigate(`/confirmation?language=${formValues.language}&number=${resp.id}&uuid=${resp.uuid}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/admin/application')
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setFormValues({ ...formValues, language })
  }, [language])

  function getSuffixOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Jr', label: translate('Jr') },
      { value: 'Sr', label: translate('Sr') },
      { value: 'II', label: translate('II') },
      { value: 'III', label: translate('III') },
      { value: 'IV', label: translate('IV') }
    ]
  }

  function getPhoneTypeOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Home', label: translate('Home') },
      { value: 'Work', label: translate('Work') },
      { value: 'Mobile', label: translate('Mobile') }
    ]
  }

  function getRaceEthnicityOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Asian', label: translate('Asian') },
      { value: 'Black', label: translate('Black') },
      { value: 'Hispanic', label: translate('Hispanic') },
      { value: 'White', label: translate('White') },
      { value: 'Multiple', label: translate('Multiple') },
      { value: 'Native American', label: translate('Native American') },
      { value: 'Other', label: translate('Other') }
    ]
  }

  const tribeOptions = [
    { value: '', label: '-' },
    { value: 'Alabama Quassarte Tribal Town', label: 'Alabama Quassarte Tribal Town' },
    { value: 'Absentee Shawnee Tribe', label: 'Absentee Shawnee Tribe' },
    { value: 'Caddo Nation', label: 'Caddo Nation' },
    { value: 'Cherokee Nation', label: 'Cherokee Nation' },
    { value: 'Chickasaw Nation', label: 'Chickasaw Nation' },
    { value: 'Choctaw Nation', label: 'Choctaw Nation' },
    { value: 'Citizen Potawatomi Nation', label: 'Citizen Potawatomi Nation' },
    { value: 'Comanche Nation', label: 'Comanche Nation' },
    { value: 'Delaware Nation', label: 'Delaware Nation' },
    { value: 'Eastern Shawnee Tribe', label: 'Eastern Shawnee Tribe' },
    { value: 'Fort Sill Apache Tribe', label: 'Fort Sill Apache Tribe' },
    { value: 'Iowa Tribe', label: 'Iowa Tribe' },
    { value: 'Kaw Nation', label: 'Kaw Nation' },
    { value: 'Kickapoo Tribe', label: 'Kickapoo Tribe' },
    { value: 'Kiowa Tribe', label: 'Kiowa Tribe' },
    { value: 'Miami Tribe', label: 'Miami Tribe' },
    { value: 'Muscogee (Creek) Nation', label: 'Muscogee (Creek) Nation' },
    { value: 'Osage Nation', label: 'Osage Nation' },
    { value: 'Otoe-Missouria Tribe', label: 'Otoe-Missouria Tribe' },
    { value: 'Ottawa Tribe', label: 'Ottawa Tribe' },
    { value: 'Pawnee Nation', label: 'Pawnee Nation' },
    { value: 'Peoria Tribe of Indians', label: 'Peoria Tribe of Indians' },
    { value: 'Ponca Nation', label: 'Ponca Nation' },
    { value: 'Quapaw Tribe', label: 'Quapaw Tribe' },
    { value: 'Sac & Fox Nation', label: 'Sac & Fox Nation' },
    { value: 'Seminole Nation', label: 'Seminole Nation' },
    { value: 'Seneca-Cayuga Tribe', label: 'Seneca-Cayuga Tribe' },
    { value: 'Shawnee Tribe', label: 'Shawnee Tribe' },
    { value: 'Tonkawa Tribe', label: 'Tonkawa Tribe' },
    { value: 'United Keetoowah Band of Cherokees', label: 'United Keetoowah Band of Cherokees' },
    { value: 'Wichita & Affiliated Tribes', label: 'Wichita & Affiliated Tribes' },
    { value: 'Wyandotte Nation', label: 'Wyandotte Nation' },
    { value: 'Other', label: 'Other' }
  ]

  const roleOptions = [
    { value: 'Parent / Guardian', label: translate('Parent / Guardian') },
    { value: 'Non-student child', label: translate('Non-student child') },
    { value: 'Other household member', label: translate('Other household member') }
  ]

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.log(`No translation found for '${text}'`)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  const links = (
    <>
      <li><Link to='/' role='button'>{translate('HOME')}</Link></li>
      <li>{translate('APPLICATION')}</li>
      <li><Link to='/cherokee-nation-benefits' role='button'>{translate('CHEROKEE NATION BENEFITS')}</Link></li>
      <li><Link to='/chickasaw-nation-benefits' role='button'>{translate('CHICKASAW NATION BENEFITS')}</Link></li>
      <li>
        {/* <label id='language-menu-label' for='language-menu' className='screen-reader-only'>Language:</label> */}
        <select
          onChange={(event) => {
            setLanguage(event.target.value)
            setIsOpen(false)
          }}
          id='language-menu'
          ariay-labelledby='language-menu-label'
          name='language-menu'
          value={language}
        >
          <option value='English'>{translate('ENGLISH')}</option>
          <option value='Spanish'>{translate('SPANISH')}</option>
        </select>
      </li>
    </>
  )

  const form = (
    <form onSubmit={e => e.preventDefault()}>
      <RadForm
        actions={
          <RadSpaceBetween direction='horizontal' size='xs'>
            {user != null && <RadButton variant='link' onClick={() => navigate(searchParams.get('redirectURL') ?? '/admin/application')}>Cancel</RadButton>}
            {user == null || formValues.consentToApplyOnBehalfOf === true
              ? <RadButton formAction='submit' variant='primary' disabled={isNullOrWhitespace(formValues.signature) || formValues.iCertify !== true || formValues.agreeEsignature !== true} onClick={() => create()}>{translate('Submit Application')}</RadButton>
              : null}
          </RadSpaceBetween>
        }
      >
        <RadSpaceBetween size='l'>
          {/* <RadSelect
            filteringType='none'
            selectedOption={languageOptions.find(x => x.value === formValues.language)}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, language: detail.selectedOption.value })
              setLanguage(detail.selectedOption.value)
            }}
            options={languageOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a language')}
            empty={translate('No matches found')}
          /> */}

          {user != null &&
            <div style={{ paddingTop: '60px' }}>
              <RadBox textAlign='center'>
                <RadFormField field='consentToApplyOnBehalfOf'>
                  <RadCheckbox
                    checked={formValues.consentToApplyOnBehalfOf ?? false}
                    onChange={({ detail }) => {
                      setFormValues({ ...formValues, consentToApplyOnBehalfOf: detail.checked })
                    }}
                  >
                    {translate('I have the applicant\'s permission to submit this application on their behalf.')}
                  </RadCheckbox>
                </RadFormField>
              </RadBox>
            </div>}

          {user != null && formValues.consentToApplyOnBehalfOf === true &&
            <OverrideEditor formValues={formValues} setFormValues={setFormValues} translate={translate} />}

          {(user == null || formValues.consentToApplyOnBehalfOf === true) &&
            <>
              {/* Primary Contact Information */}
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {translate('Primary Contact Information')}
                  </RadHeader>
                }
              >
                {/* <div> */}
                {/* <h2>
                  {translate('Primary Contact Information')}
                </h2> */}
                <PrimaryContactEditor
                  primaryContact={formValues.primaryContact}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  suffixOptions={getSuffixOptions()}
                  phoneTypeOptions={getPhoneTypeOptions()}
                  raceEthnicityOptions={getRaceEthnicityOptions()}
                  roleOptions={roleOptions}
                  tribeOptions={tribeOptions}
                  translate={translate}
                />
                {/* </div> */}
              </RadContainer>

              <SecondaryContact
                secondaryContact={formValues.secondaryContact}
                formValues={formValues}
                setFormValues={setFormValues}
                suffixOptions={getSuffixOptions()}
                phoneTypeOptions={getPhoneTypeOptions()}
                translate={translate}
              />

              {/* Household Information */}
              <RadContainer
                header={
                  <RadHeader
                    variant='h2'
                  >
                    {translate('Household Information')}
                  </RadHeader>
                }
              >
                <HouseholdEditor
                  household={formValues.household}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  translate={translate}
                />
              </RadContainer>

              <Children
                formValues={formValues}
                setFormValues={setFormValues}
                suffixOptions={getSuffixOptions()}
                raceEthnicityOptions={getRaceEthnicityOptions()}
                tribeOptions={tribeOptions}
                translate={translate}
              />

              <HouseholdMembers
                formValues={formValues}
                setFormValues={setFormValues}
                suffixOptions={getSuffixOptions()}
                raceEthnicityOptions={getRaceEthnicityOptions()}
                roleOptions={roleOptions}
                tribeOptions={tribeOptions}
                translate={translate}
              />

              <ConsentAndSignature
                formValues={formValues}
                setFormValues={setFormValues}
                translate={translate}
              />

              <Footer translate={translate} />
            </>}
        </RadSpaceBetween>
      </RadForm>
    </form>
  )

  // if (user == null) {
  return (
    <>
      <section className='main_div main_menu_wrap menu_section_wrap'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 mnu_top text-center'>
              <ul className='web_nav'>
                {links}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className='responsive_header white-background' id='back_top_menu'>
        <div className='container-fluid px-lg-5'>
          <div className='row pding align-items-center'>
            <div className='col-8 ps-5' />
            <div className='mobile_icon_wrap col-4' onClick={() => setIsOpen(!isOpen)}>
              <div className='mobile_icon'>
                <i className='fas fa-bars' aria-hidden='true' />
              </div>
            </div>
          </div>
        </div>
        <ul id={isOpen ? 'open_nav' : null} className='top_menu responsive_header_nav pt-5 web_nav_wrap'>
          {links}
        </ul>
      </div>
      <div className='menu_holder' />

      {/* <section className='main_div eligibility_blue' style={{ margin: '0 0 50px 0' }}>
          <div className='container-fluid px-lg-5'>
            <h1 className='normal-case white' style={{ fontSize: '28px' }}>
              {translate('Welcome! Please complete one form for your entire household, listing all children and other adults living in the household.')}
            </h1>
          </div>
        </section> */}

      <section>
        <SiteBanner message={translate('Applications for the 2024 Summer EBT program are now closed.')} />
      </section>

      {/* <section>
          <div className='container-fluid px-lg-5'>
            {form}
          </div>
        </section> */}
    </>
  )
}
//   } else {
//     return <></>
//     // (
//     //   <RadAppLayout
//     //     name={translate('Application')}
//     //     contentHeader={
//     //       <RadHeader variant='h1'>
//     //         {translate('New Application')}
//     //       </RadHeader>
//     //     }
//     //     content={form}
//     //   />
//     // )
//   }
// }

function ConsentAndSignature ({ formValues, setFormValues, translate }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
        >
          {translate('Before submitting your application, please review the following information.')}
        </RadHeader>
      }
    >
      <RadSpaceBetween size='s'>
        <RadBox color='text-body-secondary'>
          <RadSpaceBetween size='xxs'>
            <span>{translate('The Richard B. Russell National School Lunch Act requires the information on this application. You do not have to give the information, but if you do not, we cannot approve your child for free and reduced-price meals. We will use your information to determine if your child is eligible for Summer EBT benefits.')}</span>
            <span>{translate('The Summer EBT program MAY share your eligibility information with education, health, and nutrition programs to help them evaluate, fund, or determine benefits for their programs, auditors for program reviews, and law enforcement officials to help them look into violations of program rules.')}</span>
            <span>{translate('Foster, migrant, homeless, and runaway children, and children enrolled in a Head Start program, are categorically eligible for free meals and free milk. If you are completing an application for these children, contact the school for more information.')}</span>
            <span>{translate('Participation in this program will have no impact on other government assistance you and your child receive.')}</span>
          </RadSpaceBetween>
        </RadBox>
        <RadFormField field='iCertify' stretch label={translate('I certify that all information furnished in the application is true and correct, that the application is being made in connection with the receipt of Federal funds, that school officials may verify the information on the application, and that deliberate misrepresentation of the information may subject me to prosecution under applicable State and Federal criminal statutes.')} screenReader>
          <RadCheckbox
            checked={formValues.iCertify}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, iCertify: detail.checked })
            }}
          >
            {translate('I certify that all information furnished in the application is true and correct, that the application is being made in connection with the receipt of Federal funds, that school officials may verify the information on the application, and that deliberate misrepresentation of the information may subject me to prosecution under applicable State and Federal criminal statutes.')}
          </RadCheckbox>
        </RadFormField>
        <RadFormField field='agreeEsignature' stretch label={translate('Electronically signing this form shall be considered as valid as a physical signature.')} screenReader>
          <RadCheckbox
            checked={formValues.agreeEsignature}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, agreeEsignature: detail.checked })
            }}
          >
            {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
          </RadCheckbox>
        </RadFormField>

        <RadFormField label={translate('eSignature')} field='primaryContact.signature' description={translate('Type your full name below to electronically sign.')}>
          <div className='signature-container'>
            <input
              id='formField:r44:'
              aria-labelledby='formField:r44:-label'
              className='signature'
              aria-required
              value={formValues.signature ?? ''}
              onChange={e => {
                setFormValues({ ...formValues, signature: e.target.value })
              }}
            />
          </div>
        </RadFormField>
      </RadSpaceBetween>
    </RadContainer>
  )
}
