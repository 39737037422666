
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2rhyz_1byw5_141",
  "input": "awsui_input_2rhyz_1byw5_145",
  "input-readonly": "awsui_input-readonly_2rhyz_1byw5_195",
  "input-invalid": "awsui_input-invalid_2rhyz_1byw5_262",
  "input-has-icon-left": "awsui_input-has-icon-left_2rhyz_1byw5_271",
  "input-warning": "awsui_input-warning_2rhyz_1byw5_274",
  "input-type-search": "awsui_input-type-search_2rhyz_1byw5_286",
  "input-has-icon-right": "awsui_input-has-icon-right_2rhyz_1byw5_299",
  "input-has-no-border-radius": "awsui_input-has-no-border-radius_2rhyz_1byw5_302",
  "input-container": "awsui_input-container_2rhyz_1byw5_309",
  "input-icon-left": "awsui_input-icon-left_2rhyz_1byw5_314",
  "input-icon-right": "awsui_input-icon-right_2rhyz_1byw5_321",
  "input-button-right": "awsui_input-button-right_2rhyz_1byw5_327"
};
  