
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_1kosq_15j42_141",
  "breadcrumb": "awsui_breadcrumb_1kosq_15j42_145",
  "icon": "awsui_icon_1kosq_15j42_148",
  "anchor": "awsui_anchor_1kosq_15j42_153",
  "last": "awsui_last_1kosq_15j42_199",
  "compressed": "awsui_compressed_1kosq_15j42_209",
  "text": "awsui_text_1kosq_15j42_213",
  "virtual-item": "awsui_virtual-item_1kosq_15j42_220",
  "text-hidden": "awsui_text-hidden_1kosq_15j42_227"
};
  