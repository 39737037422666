import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { formatDate } from '../common/utilities'
import { states, AuditListRowForm } from './AuditListRowForm'

const statusOptions = [{ label: 'Any', value: '' }]
Object.keys(states).forEach(k => {
  statusOptions.push({
    label: states[k].displayName,
    value: k
  })
})

export function AuditList () {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const { data: children, count } = useGet(`/api/audit?search=${encodeURIComponent(searchText)}&status=${searchStatus}`)

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  const makeString = (arr) => arr.filter(s => s != null).join(' ')

  if (children != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={`(${count})`}
            description='You can search by application number, child name, birth date, primary contact name, primary contact phone.'
          >
            Audit
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadTable
              columnDefinitions={[
                {
                  id: 'applicationId',
                  header: 'Application ID',
                  cell: item => <RadLink href={`/admin/application/${item.application_id}`}>{highlightMatch(item.application_id.toString())}</RadLink>
                },
                {
                  id: 'fullName',
                  header: 'Child',
                  cell: item => {
                    const fullName = makeString([item.first_name, item.middle_name, item.last_name])
                    return <div key={'full_name-' + item.id}>{highlightMatch(fullName)}</div>
                  }
                },
                {
                  id: 'birthDate',
                  header: 'Birth Date',
                  cell: item => <div key={'birth_date-' + item.id}>{highlightMatch(formatDate(item.birth_date))}</div>
                },
                {
                  id: 'primaryContact',
                  header: 'Primary Contact',
                  cell: item => {
                    const fullName = makeString([item.pc_first_name, item.pc_middle_name, item.pc_last_name])
                    return <div key={'primary_contact-' + item.id}>{highlightMatch(fullName)}</div>
                  }
                },
                {
                  id: 'phone',
                  header: 'Phone',
                  cell: item =>
                    [item.phone1, item.phone2]
                      .filter(s => s != null)
                      .map((s, i) => <div key={`phone-${item.id}-${i}`}>{highlightMatch(s)}</div>)
                },
                {
                  id: 'email',
                  header: 'Email',
                  cell: item => <div key={'email-' + item.id}>{item.pc_email}</div>
                },
                {
                  id: 'form',
                  header: 'Action',
                  minWidth: '18rem',
                  cell: item => <AuditListRowForm child={item} key={item.id} />
                }
              ]}
              items={children}
              variant='full-page'
              filter={
                <RadGrid
                  gridDefinition={[
                    { colspan: { default: 12, xs: 6 } },
                    { colspan: { default: 12, xs: 3 } }
                  ]}
                >
                  <RadTextFilter
                    stretch
                    filteringPlaceholder='Search'
                    filteringAriaLabel='Search participants'
                    filteringText={filteringText}
                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                    onDelayedChange={({ detail }) => {
                      setSearchText(detail.filteringText)
                      if (detail.filteringText) {
                        searchParams.set('search', detail.filteringText)
                      } else {
                        searchParams.delete('search')
                      }
                      setSearchParams(searchParams)
                    }}
                  />
                  <RadSelect
                    onChange={({ detail }) => {
                      setSearchStatus(detail.selectedOption.value)
                      if (detail.selectedOption.value === '') {
                        searchParams.delete('status')
                      } else {
                        searchParams.set('status', detail.selectedOption.value)
                      }
                      setSearchParams(searchParams)
                    }}
                    options={statusOptions}
                    selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                    placeholder='Choose status'
                  />
                </RadGrid>
              }
              empty={
                <RadBox textAlign='center' color='inherit'>
                  No matches found.
                </RadBox>
              }
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
