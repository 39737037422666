
    import './styles.scoped.css';
    export default {
  "navigation": "awsui_navigation_2p2ab_z2esl_149",
  "is-navigation-open": "awsui_is-navigation-open_2p2ab_z2esl_177",
  "animating": "awsui_animating_2p2ab_z2esl_181",
  "openNavigation": "awsui_openNavigation_2p2ab_z2esl_1",
  "animated-content": "awsui_animated-content_2p2ab_z2esl_194",
  "hide-navigation": "awsui_hide-navigation_2p2ab_z2esl_203"
};
  