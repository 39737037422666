import React, { useContext } from 'react'
import FormField from '@cloudscape-design/components-themed/form-field'
import { AppContext } from './../App'
import { translations } from '../translations'

// https://cloudscape.design/components/form-field
export function RadFormField ({ field, label, required, screenReader, ...rest }) {
  const { error, language } = useContext(AppContext)
  const errorText = field != null && error != null && error.validationErrors != null ? error.validationErrors[field] : null

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.warn('No translation found for', text)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  const classes = []
  if (screenReader) {
    classes.push('screen-reader-only')
  }
  if (required) {
    classes.push('required')
  }

  return (
    <FormField
      errorText={errorText != null ? translate(errorText) : null}
      label={classes.length > 0 ? <span className={classes.join(' ')}>{label}</span> : <span>{label}</span>}
      {...{ ...rest }}
    />
  )
}
