
    import './styles.scoped.css';
    export default {
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_132o9_141",
  "trigger": "awsui_trigger_lpshu_132o9_141",
  "selected": "awsui_selected_lpshu_132o9_230",
  "badge": "awsui_badge_lpshu_132o9_250",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_132o9_254",
  "dot": "awsui_dot_lpshu_132o9_262"
};
  